import { Input, Text } from "@chakra-ui/react";
import { Field } from "formik";
import { HTMLInputTypeAttribute, ReactElement } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  name: string;
  placeholder: string;
  type?: HTMLInputTypeAttribute;
  autocomplete?: string;
  id?: string;
};

export const LoginInput = ({
  name,
  placeholder,
  type,
  autocomplete,
  id,
}: Props): ReactElement => {
  const { t } = useTranslation();

  return (
    <Field name={name}>
      {/* @ts-ignore */}
      {({ field, form }) => (
        <>
          <Input
            fontFamily="Lato"
            fontSize="xs"
            placeholder={t(placeholder)}
            type={type ? type : "text"}
            value={name}
            mb="1rem"
            autoComplete={autocomplete}
            id={id}
            required={false}
            {...field}
          />
          {form.touched[name] && form.errors[name] ? (
            <Text fontSize="xs" color="red.500" mt={-4} mb={5}>
              {t(form.errors[name])}
            </Text>
          ) : null}
        </>
      )}
    </Field>
  );
};
