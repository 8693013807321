export const adminRegistrationFromInputs = [
  { name: "name", placeHolder: "Name" },
  { name: "surname", placeHolder: "Surname" },
  { name: "company", placeHolder: "Company name" },
  { name: "birthdate", placeHolder: "Birthdate", type: "date" },
  { name: "email", placeHolder: "Email" },
  { name: "password", placeHolder: "Password", type: "password" },
  {
    name: "confirmationPassword",
    placeHolder: "Repeat password",
    type: "password",
  },
];
