import { RegisterForm } from "components/forms/RegisterForm";
import { LoginLayout } from "layout/LoginLayout";
import { ReactElement } from "react";

export const Register = (): ReactElement => {
  return (
    <LoginLayout heading="Welcome to KOSKO">
      <RegisterForm />
    </LoginLayout>
  );
};
