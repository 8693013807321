import { useState, useEffect, useContext } from "react";
import {
  GET_USER_LIST,
  GET_DEPARTMENT_LIST,
  GET_ALL_COMPANIES,
} from "graphql/queries";
import { useQuery } from "@apollo/client";
import { Link, useLocation } from "react-router-dom";
import { LoadingPage } from "pages/loading/LoadingPage";
import { AuthContext } from "context/Auth";
import { Flex, Text } from "@chakra-ui/react";
import { BiBuildings } from "react-icons/bi";

type UserData = Array<{
  __typename: "DepartmentUsersListDto";
  sub: string;
  name: string;
  surname: string;
  email: string;
}>;

type DepartmentsData = Array<{
  departmentId: string;
  departmentName: string;
  managerName: string;
  companyName: string;
}>;

type Companies = Array<{
  name: string | null;
  surname: string;
  company: {
    id: string;
    companyName: string;
  };
}>;

export const UsersList = () => {
  const [usersData, setUsersData] = useState<UserData | null>();
  const [departmentsData, setDepartmentsData] =
    useState<DepartmentsData | null>();
  const [departmentName, setDepartmentName] = useState<string | null>();
  const [companyName, setCompanyName] = useState<string | null>();
  const [companies, setCompanies] = useState<Companies | null>();

  const { pathname, state } = useLocation();

  let companyId;
  const storedValue = localStorage.getItem("companyId");

  if (storedValue && JSON.parse(storedValue)) {
    companyId = JSON.parse(storedValue);
  } else if (state && state.companyId) {
    companyId = state.companyId;
  }

  let managerName: string;

  if (state) {
    localStorage.setItem("chosenDepartment", state.department);
    localStorage.setItem("chosenDepartmentId", state.departmentId);
    if (state.companyName) {
      localStorage.setItem("chosenCompany", state.companyName);
    }
    if (state.companyId) {
      localStorage.setItem("companyId", JSON.stringify(state.companyId));
    }
    managerName = state.managerName;
  }

  let departmentId;
  if (pathname.includes("users/departments")) {
    const paths = pathname.split("/");
    departmentId = paths[paths.length - 1];
  } else if (localStorage.departmentId) {
    const storedValue = localStorage.getItem("departmentId");
    departmentId = storedValue ? JSON.parse(storedValue) : null;
  }

  const { isAdmin, isManager, user, isSuperAdmin } = useContext(AuthContext);

  const { loading: departmentsLoading, data: departmentsListData } = useQuery(
    GET_DEPARTMENT_LIST,
    {
      fetchPolicy: "network-only",
      variables: {
        companyId: companyId,
      },
      skip: isManager || !companyId,
    }
  );

  const { loading, data } = useQuery(GET_USER_LIST, {
    fetchPolicy: "network-only",
    variables: isManager
      ? { departmentId: departmentId || "", managerId: user?.sub }
      : { departmentId: departmentId || "", managerId: null },
    skip: !departmentId,
  });

  const { loading: companiesLoading, data: companiesData } = useQuery(
    GET_ALL_COMPANIES,
    {
      fetchPolicy: "network-only",
      skip: pathname !== "/companies",
    }
  );

  useEffect(() => {
    if (departmentsListData) {
      setDepartmentsData(
        departmentsListData.companyDepartmentList as DepartmentsData
      );
      setCompanyName(departmentsListData.companyDepartmentList[0].companyName);
    }
  }, [departmentsListData, departmentsLoading]);

  useEffect(() => {
    if (data) {
      setDepartmentName(data.departmentUsersList.departmentName);
      setUsersData(data.departmentUsersList.users as UserData);
    }
  }, [data, loading]);

  useEffect(() => {
    if (companiesData) {
      setCompanies(companiesData.getAllCompanies as Companies);
    }
  }, [companiesData, companiesLoading]);

  const renderData = (data: any, isUser: boolean) => {
    let dataForRender = [...data];
    if ((isUser && isAdmin) || isSuperAdmin) {
      const sortedUsers = sortUsers(data, managerName);
      dataForRender = [...sortedUsers];
    }

    return dataForRender.map((item: any, index: number) => (
      <Link
        to={
          isUser && isSuperAdmin
            ? "#"
            : isUser && !isSuperAdmin
            ? `/users/${item.sub}`
            : companies
            ? "/users"
            : `/users/departments/${item.departmentId}`
        }
        onClick={(event) => {
          if (isUser && isSuperAdmin) {
            event.preventDefault();
          }
        }}
        state={
          isUser
            ? { name: `${item.name} ${item.surname}` }
            : companies
            ? {
                companyId: item.company.id,
                companyName: item.company.companyName,
              }
            : {
                department: item.departmentName,
                departmentId: item.departmentId,
                managerName: item.managerName,
              }
        }
        key={
          isUser ? item.email : companies ? item.company.id : item.departmentId
        }
      >
        <Flex
          w="100%"
          fontSize="14px"
          background={
            pathname.includes("departments")
              ? index === 0
                ? "#f5f2fc"
                : index % 2 === 0
                ? "#faf8ff"
                : "#ffffff"
              : index % 2 === 0
              ? "#ffffff"
              : "#faf8ff"
          }
          _hover={{ background: "#eae5fa", cursor: "pointer" }}
          py={3}
        >
          <Flex w="26%" px={6}>
            {index + 1}
          </Flex>
          <Flex w="46%">
            {isUser
              ? `${item.name} ${item.surname}`
              : companies
              ? item.company.companyName
              : item.departmentName}
          </Flex>
          <Flex w="28%">
            {isUser
              ? item.email
              : companies
              ? `${item.name ? item.name : ""} ${item.surname}`
              : item.managerName}
          </Flex>
        </Flex>
      </Link>
    ));
  };

  const sortUsers = (users: UserData, managerName: string) => {
    const sortedList = [...users];

    const managerIndex = sortedList.findIndex(
      (user) => `${user.name} ${user.surname}` === managerName
    );

    if (managerIndex !== -1) {
      const managerUser = sortedList.splice(managerIndex, 1)[0];
      sortedList.unshift(managerUser);
    }

    return sortedList;
  };

  return (
    <Flex flexDir="column" gap="24px">
      {loading && <LoadingPage />}
      {(departmentName || companyName || companies) && (
        <Flex alignItems="center" gap="8px">
          <BiBuildings />
          <Text fontWeight="bold" fontSize="19px">
            {departmentName || companyName || "Companies"}
          </Text>
        </Flex>
      )}
      {(usersData?.length || departmentsData?.length || companies?.length) && (
        <Flex w="100%" maxW="1260px">
          <Flex w="100%" flexDir="column" borderRadius="xl">
            <Flex
              color="#333333"
              fontSize="14px"
              background="#eae5fa"
              fontWeight="semibold"
              textTransform="none"
              borderTopLeftRadius="xl"
              borderTopRightRadius="xl"
              alignItems="center"
            >
              <Flex w="26%" px={6} py={3}>
                Numero
              </Flex>
              <Flex w="46%" py={3}>
                {departmentId
                  ? "Membri"
                  : companies
                  ? "Nomme delle aziende"
                  : "Dipartimenti"}
              </Flex>
              <Flex w="28%" py={3}>
                {departmentId ? "Email" : companies ? "Ammiss" : "Manager"}
              </Flex>
            </Flex>
            <Flex flexDir="column" w="100%">
              {usersData
                ? renderData(usersData, true)
                : companies
                ? renderData(companies, false)
                : renderData(departmentsData, false)}
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
