import {
  RadialBarChart,
  RadialBar,
  Legend,
  ResponsiveContainer,
  Cell,
  PolarAngleAxis,
} from "recharts";
import { Box, Flex } from "@chakra-ui/react";
import { GraphData } from "./graphTypes";
import { useTranslation } from "react-i18next";
import { ReactElement, useContext } from "react";
import { AuthContext } from "context/Auth";
import { useLocation } from "react-router-dom";

export const Radial = ({ data }: GraphData): ReactElement => {
  const { t } = useTranslation();
  const showNoDataMessage = !data || data.length === 0;
  const { isAdmin, isManager } = useContext(AuthContext);
  const { pathname } = useLocation();

  let legendLayout: "horizontal" | "vertical";
  let legendAlign: "left" | "center";

  if ((isAdmin || isManager) && pathname === "/results") {
    legendLayout = "horizontal";
    legendAlign = "left";
  } else {
    legendLayout = "vertical";
    legendAlign = "center";
  }

  return (
    <Box position="relative">
      <ResponsiveContainer width={216} height={320}>
        {showNoDataMessage ? (
          <Flex h="100%" alignItems="center">
            <p>{t("Not enough data to display the chart")}</p>
          </Flex>
        ) : (
          <RadialBarChart
            innerRadius="15%"
            outerRadius="80%"
            cx="50%"
            cy="50%"
            data={data}
            maxBarSize={12}
          >
            <PolarAngleAxis
              type="number"
              domain={[0, 100]}
              angleAxisId={0}
              tick={false}
            />

            <RadialBar
              background={{ fill: "#f6f6f6" }}
              cornerRadius={15}
              dataKey="value"
            >
              {data?.length &&
                data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={entry.color}
                    fillOpacity="red"
                  />
                ))}
            </RadialBar>
            <Legend
              layout={legendLayout}
              verticalAlign="bottom"
              align={legendAlign}
              iconType="circle"
              width={250}
              iconSize={9}
              formatter={(value: string) => {
                let count;
                if (data && data.length > 0) {
                  const item = data.find((item) => item.name === value);
                  if (item) {
                    count = item.count;
                  }
                }
                return (
                  <Flex justifyContent="space-between" display="inline-block">
                    <span
                      style={{
                        color: "black",
                        textTransform: "capitalize",
                      }}
                    >
                      {value.toLowerCase()}
                    </span>
                    <span
                      style={{
                        color: "black",
                        position: "absolute",
                        right: 0,
                        fontSize: "13px",
                      }}
                    >
                      {count}
                    </span>
                  </Flex>
                );
              }}
              wrapperStyle={{ fontSize: "0.75rem" }}
            />
          </RadialBarChart>
        )}
      </ResponsiveContainer>
    </Box>
  );
};
