import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  ResponsiveContainer,
} from "recharts";
import { GraphData } from "./graphTypes";
import { Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ReactElement } from "react";

export const PersonalityGraph = ({ data }: GraphData): ReactElement => {
  const { t } = useTranslation();

  const showNoDataInformation = !data || data.length <= 2;

  return (
    <ResponsiveContainer width={370} height={330}>
      {showNoDataInformation ? (
        <Flex h="100%" alignItems="center">
          <p>{t("Not enough data to display the chart")}</p>
        </Flex>
      ) : (
        <RadarChart cx="50%" cy="50%" outerRadius={100} data={data}>
          <PolarGrid />
          <PolarAngleAxis
            tick={{
              fontSize: 14,
              fontFamily: "DM Sans",
              fontWeight: "bold",
              fill: "#000000",
            }}
            dataKey="name"
          />
          <Radar
            name="Mike"
            dataKey="value"
            stroke="#87c0d1"
            strokeWidth={5}
            fill="#dbecf1"
            fillOpacity={0.3}
          />
        </RadarChart>
      )}
    </ResponsiveContainer>
  );
};
