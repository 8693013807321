import { AbsoluteCenter, Box, HStack, Text, VStack } from "@chakra-ui/react";
import { Field, useFormikContext } from "formik";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  answerId: string;
  styleId: string;
  title: string;
  values: number[];
};

interface SurveyValues {
  [answerId: string]: {
    value: number;
    answerId: string;
    styleId: string;
    duration: string;
  };
}

export const Answer = ({
  title,
  values: answerValues,
  answerId,
  styleId,
}: Props): ReactElement => {
  const [selectedAnswer, setSelectedAnswer] = useState(-1);
  const { setFieldValue, values, validateForm } =
    useFormikContext<SurveyValues>();

  const { t } = useTranslation();

  useEffect(() => {
    if (values[answerId]) {
      setSelectedAnswer(values[answerId].value);
      validateForm();
    }
  }, [answerId, values, validateForm]);

  return (
    <VStack
      borderRadius="xl"
      background="rgba(116, 84, 240, 0.07)"
      p={5}
      paddingLeft={10}
      paddingRight={10}
      w="30rem"
    >
      <Text fontFamily="Roboto" fontSize="md" mb={3} alignSelf="flex-start">
        {title}
      </Text>
      <HStack justifyContent="space-between" w="100%">
        {answerValues.map((option) => (
          <Box
            key={`${answerId}-${option}`}
            boxSize="40px"
            border=".9px solid rgba(116, 84, 240, 0.7)"
            background={
              selectedAnswer === option ? "rgba(116, 84, 240, 0.3)" : "white"
            }
            _hover={{ background: "#E0D9FC" }}
            borderRadius="sm"
            position="relative"
            cursor="pointer"
            onClick={() => {
              setSelectedAnswer(option);
              setFieldValue(answerId, {
                ...values[answerId],
                value: option,
                answerId,
                styleId,
                duration: "",
              });
            }}
          >
            <AbsoluteCenter>
              <Text fontFamily="Roboto" fontSize="md">
                <Field as="span" name={answerId} value={option}>
                  {option}
                </Field>
              </Text>
            </AbsoluteCenter>
          </Box>
        ))}
      </HStack>
      <HStack justify="space-between" width="100%">
        <Text fontSize="xs" color="gray">
          {t("Not likely at all")}
        </Text>
        <Text fontSize="xs" color="gray">
          {t("Extremely likely")}
        </Text>
      </HStack>
    </VStack>
  );
};
