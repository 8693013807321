type Paths = Record<string, string>;

export const paths: Paths = {
  dashboard: "/dashboard",
  profile: "/profile",
  surveyManager: "/survey-manager",
  users: "/users",
  departmentUsers: "/users/departments/:id",
  companies: "/companies",
  userResults: "/users/:id",
  login: "/login",
  register: "/register",
  forgotPassword: "/forgot-password",
  home: "/",
  survey: "/survey",
  registerAdmin: "/register-admin",
  results: "/results",
};

export const pathNames: Paths = {
  "/dashboard": "Home",
  "/profile": "Profile",
  "/survey-manager": "Survey Manager",
  "/users": "Users",
  "/results": "Results",
};
