import { ForgotPasswordForm } from "components/forms/ForgotPasswordForm";

import { LoginLayout } from "layout/LoginLayout";
import { ReactElement } from "react";

export const ForgotPassword = (): ReactElement => {
  return (
    <LoginLayout heading="Forgot password?">
      <ForgotPasswordForm />
    </LoginLayout>
  );
};
