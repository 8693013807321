import { Box, Heading, Text } from "@chakra-ui/react";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  mainText: string;
  subText?: string | null;
};

export const InfoCard = ({
  mainText,
  subText = "Text",
}: Props): ReactElement => {
  const { t } = useTranslation();

  return (
    <Box
      background="#ffffff"
      p={5}
      pl={8}
      pr={8}
      borderRadius="lg"
      w={256}
      h={98}
    >
      <Text
        fontFamily="Mulish"
        fontWeight={700}
        textAlign="center"
        color="#555461"
        mb={3}
      >
        {t(mainText)}
      </Text>
      <Heading
        fontFamily="Nunito"
        fontSize="xl"
        textAlign="center"
        color="#8067c9"
      >
        {t(subText || "")}
      </Heading>
    </Box>
  );
};
