import { useMutation } from "@apollo/client";
import { REGISTER_USER_WITH_DEPARTMENT } from "../graphql/queries";

export const useRegister = () => {
  const [registerUser, { data, error, loading }] = useMutation(
    REGISTER_USER_WITH_DEPARTMENT
  );

  const handleRegister = (
    email: string,
    password: string,
    name: string,
    surname: string,
    birthdate: string,
    companyId: string,
    departmentId: string
  ) => {
    return registerUser({
      variables: {
        email,
        password,
        name,
        surname,
        birthdate,
        companyId,
        departmentId,
      },
    });
  };

  return {
    handleRegister,
    data,
    error,
    loading,
  };
};
