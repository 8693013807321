import {
  defineStyleConfig,
  extendTheme,
  withDefaultColorScheme,
} from "@chakra-ui/react";

const Button = defineStyleConfig({
  // Two variants: outline and solid
  variants: {
    outline: {
      border: "2px solid",
      borderColor: "#7454F0",
      color: "#7454F0",
      _hover: {
        borderColor: "#8469F2",
        color: "#8469F2",
      },
    },
    solid: {
      bg: "#7454F0",
      color: "white",
      _hover: { bg: "#8469F2" },
    },
    text: {
      width: "fit-content",
      height: "fit-content",
      padding: 0,
      color: "#7454F0",
      _hover: { color: "#8469F2", textDecoration: "underline" },
    },
  },
  // The default size and variant values
  defaultProps: {
    size: "md",
    variant: "solid",
  },
});

export const theme = extendTheme(
  {
    colors: {
      brand: {
        400: "#8469F2",
        500: "#7454F0",
      },
    },
    fonts: {
      heading: "Mulish",
      body: "Nunito",
    },
    breakpoints: {
      sm: "320px",
      md: "768px",
      lg: "960px",
      xl: "1200px",
      "2xl": "1650px",
    },
    styles: {
      global: () => ({
        body: {
          bg: "radial-gradient(50% 50% at 50% 50%, #FAEFDF 0%, #FFD6EE 25.52%, #F1F0FF 100%);",
        },
      }),
    },
    components: {
      Button,
    },
  },
  withDefaultColorScheme({ colorScheme: "brand" })
);
