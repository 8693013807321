import React, { useState, useEffect, ReactElement } from "react";
import { useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useLocalStorage } from "hooks/useLocalStorage";
import { GET_USER } from "graphql/queries";
import { UserDto } from "gql/graphql";
import { useLogin } from "hooks/useLogin";

interface AuthContextType {
  user: UserDto | null;
  isLoading: boolean;
  loginUser: (email: string, password: string) => void;
  isAdmin: boolean;
  isManager: boolean;
  isSuperAdmin: boolean;
  setIsLoading: (loading: boolean) => void;
  logoutUser: () => void;
  isSubmitting: boolean;
}

export const AuthContext = React.createContext<AuthContextType>(
  {} as AuthContextType
);

export const AuthProvider = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => {
  const [user, setUser] = useState<UserDto | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const { setItem, getItem } = useLocalStorage();
  const { handleLogin } = useLogin();
  const { refetch } = useQuery(GET_USER, { skip: !user });

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const logoutUser = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("idToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("companyId");
    localStorage.removeItem("departmentId");
    localStorage.removeItem("chosenUser");
    localStorage.removeItem("chosenDepartment");
    localStorage.removeItem("chosenDepartmentId");

    setIsAdmin(false);
    setIsManager(false);
    setIsSuperAdmin(false);
    setUser(null);
    navigate("/login");

    setIsLoading(false);
  };

  const loginUser = (email: string, password: string) => {
    setSubmitting(true);
    setIsLoading(true);
    handleLogin(email, password)
      .then((res) => {
        if (res.data) {
          setItem("accessToken", res.data?.loginUser.accessToken);
          setItem("refreshToken", res.data?.loginUser.refreshToken);
        }

        refetch().then((r) => {
          setUser(r.data.me);
          if (r.data.me.roles?.includes("ADMIN")) {
            setIsAdmin(true);
          } else if (r.data.me.roles?.includes("MANAGER")) {
            setIsManager(true);
          } else if (r.data.me.roles?.includes("SUPERADMIN")) {
            setIsSuperAdmin(true);
          }

          setItem("userData", JSON.stringify(r.data.me));

          setItem("companyId", JSON.stringify(r.data.me.companyIdHash));
          if (r.data.me.departmentIdHash) {
            setItem("departmentId", JSON.stringify(r.data.me.departmentIdHash));
          }

          setSubmitting(false);

          r.data.me.isSurveyDone ? navigate("/dashboard") : navigate("/survey");
        });
        toast.success(t("Logged in") + " 💪🏻", { duration: 4000 });
      })
      .catch((error) => {
        toast.error(t("Failed to log-in, please try again!"), {
          duration: 4000,
        });
        toast.error(t(error?.message), { duration: 5000 });
        setSubmitting(false);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    const userDataFromStorage = getItem("userData");
    setIsLoading(true);

    if (userDataFromStorage) {
      refetch()
        .then((r) => {
          setUser(r.data.me);
          if (r.data.me.roles?.includes("ADMIN")) {
            setIsAdmin(true);
          } else if (r.data.me.roles?.includes("MANAGER")) {
            setIsManager(true);
          } else if (r.data.me.roles?.includes("SUPERADMIN")) {
            setIsSuperAdmin(true);
          }

          setItem("userData", JSON.stringify(r.data.me));

          r.data.me.isSurveyDone || r.data.me.roles?.includes("ADMIN")
            ? navigate(pathname)
            : navigate("/survey");
          setIsLoading(false);
        })

        .catch((error) => {
          toast.error(t("Failed to log-in, please try again!"), {
            duration: 4000,
          });
          toast.error(t(error?.message), { duration: 5000 });
          setSubmitting(false);
          setIsLoading(false);
          logoutUser();
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoading,
        isAdmin,
        isManager,
        isSuperAdmin,
        setIsLoading,
        loginUser,
        logoutUser,
        isSubmitting,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
