import { ReactElement, useEffect, useState } from "react";
import { Button, Flex, Checkbox, useBreakpointValue } from "@chakra-ui/react";
import { useMutation, useQuery } from "@apollo/client";
import { Field, Form, Formik } from "formik";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { GET_QUESTIONS, UPDATE_QUESTIONS } from "graphql/queries";
import { Question } from "gql/graphql";

import { DashboardLayout } from "layout/DashboardLayout";
import { getInitialValuesFromQuestions } from "pages/survey-manager/utils/getInitialValuesFromQuestions";
import { prepareQuestionUpdateData } from "pages/survey-manager/utils/prepareQuestionUpdateData";

export const SurveyManager = (): ReactElement => {
  const { loading, data } = useQuery(GET_QUESTIONS, {
    fetchPolicy: "network-only",
  });
  const [updateQuestions] = useMutation(UPDATE_QUESTIONS);
  const [initialValues, setInitialValues] = useState<{
    [key: string]: boolean;
  }>();
  const [questionsData, setQuestionsData] = useState<Question[]>();

  const { t } = useTranslation();

  useEffect(() => {
    if (loading) {
      return;
    }
    if (data?.questions) {
      setInitialValues(getInitialValuesFromQuestions(data.questions));
      setQuestionsData(data.questions);
    }
  }, [loading, data]);

  const variantFont = useBreakpointValue(
    {
      base: "md",
      xl: "lg",
    },
    {
      fallback: "base",
    }
  );

  const variantDisplay = useBreakpointValue(
    {
      base: "none",
      xl: "block",
    },
    {
      fallback: "base",
    }
  );

  const variantWidth = useBreakpointValue(
    {
      base: "7rem",
      xl: "8.25rem",
    },
    {
      fallback: "base",
    }
  );

  return (
    <DashboardLayout>
      <>
        {initialValues ? (
          <Formik
            initialValues={initialValues}
            onSubmit={(formValues, { setSubmitting }) => {
              const questionsToUpdate = prepareQuestionUpdateData(
                formValues,
                (key: string) => ({
                  id: key,
                  questionDTO: { isInSurvey: formValues[key] },
                })
              );

              updateQuestions({
                variables: { questions: questionsToUpdate },
              })
                .then(() => {
                  toast.success(t("Survey updated!"), {
                    duration: 4000,
                  });
                  setSubmitting(false);
                })
                .catch((err) => {
                  toast.error(t(err.message), { duration: 4000 });
                  setSubmitting(false);
                });
            }}
          >
            {({ isSubmitting }) => {
              return (
                <Form>
                  <Flex justifyContent="flex-end" my="2rem">
                    <Button type="submit" isDisabled={isSubmitting}>
                      {t("Publish")}
                    </Button>
                  </Flex>
                  <Flex w="100%">
                    <Flex w="100%" flexDir="column" borderRadius="xl">
                      <Flex
                        color="white"
                        fontSize={variantFont}
                        background="brand.500"
                        fontWeight="semibold"
                        textTransform="none"
                        borderTopLeftRadius="xl"
                        borderTopRightRadius="xl"
                      >
                        <Flex
                          minW="4rem"
                          px={6}
                          py={3}
                          justifyContent="flex-start"
                        >
                          <span />
                        </Flex>
                        <Flex
                          minW="8rem"
                          px={6}
                          py={3}
                          justifyContent="flex-start"
                          display={variantDisplay}
                        >
                          {t("Number")}
                        </Flex>
                        <Flex
                          w="100%"
                          px={6}
                          py={3}
                          justifyContent="flex-start"
                        >
                          {t("Question")}
                        </Flex>
                        <Flex
                          minW={variantWidth}
                          px={6}
                          py={3}
                          justifyContent="flex-start"
                        >
                          {t("Code")}
                        </Flex>
                        <Flex
                          minW={variantWidth}
                          px={6}
                          py={3}
                          justifyContent="flex-start"
                        >
                          {t("Category")}
                        </Flex>
                      </Flex>
                      <Flex flexDir="column" w="100%">
                        {questionsData?.map((question, index) => (
                          <Flex
                            w="100%"
                            background={index % 2 === 0 ? "gray.100" : ""}
                            key={question.id}
                          >
                            <Flex
                              minW="4rem"
                              justifyContent="center"
                              alignItems="baseline"
                              px={6}
                              py={3}
                            >
                              <Field name={question.id}>
                                {({
                                  field,
                                  form: { isSubmitting },
                                }: {
                                  field: { value: boolean };
                                  form: { isSubmitting: boolean };
                                }) => (
                                  <Checkbox
                                    //   @ts-ignore -> FIX ME
                                    inputProps={field}
                                    disabled={isSubmitting}
                                    name={question.id}
                                    isChecked={field.value}
                                  />
                                )}
                              </Field>
                            </Flex>
                            <Flex
                              minW="8rem"
                              px={6}
                              py={3}
                              justifyContent="flex-start"
                              alignItems="baseline"
                              display={variantDisplay}
                            >
                              <span>{JSON.stringify(index + 1)}</span>
                            </Flex>
                            <Flex
                              w="100%"
                              px={6}
                              py={3}
                              justifyContent="flex-start"
                              alignItems="baseline"
                            >
                              {question.questionName!}
                            </Flex>
                            <Flex
                              minW={variantWidth}
                              px={6}
                              py={3}
                              justifyContent="flex-start"
                              alignItems="baseline"
                            >
                              {question.questionId!}
                            </Flex>
                            <Flex
                              minW={variantWidth}
                              px={6}
                              py={3}
                              justifyContent="flex-start"
                              alignItems="baseline"
                            >
                              {question.groupId!}
                            </Flex>
                          </Flex>
                        ))}
                      </Flex>
                    </Flex>
                  </Flex>
                </Form>
              );
            }}
          </Formik>
        ) : null}
      </>
    </DashboardLayout>
  );
};
