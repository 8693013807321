import * as Yup from "yup";

export const forgotPasswordValidation = Yup.object().shape({
  code: Yup.string()
    .min(6, "Code should be 6 digits")
    .max(6, "Code should be 6 digits")
    .matches(/^([0-9])/, "Code should be numeric")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),

  password: Yup.string()
    .required("Required")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
  confirmationPassword: Yup.string().oneOf(
    [Yup.ref("password")],
    "Passwords must match"
  ),
});
