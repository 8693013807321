import { ReactElement } from "react";
import { GraphData } from "./graphTypes";
import { Box, Flex, Center } from "@chakra-ui/react";
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from "recharts"; // Renamed PieChart import
import { useTranslation } from "react-i18next";

export const PieChartGraph = ({ data }: GraphData): ReactElement => {
  const { t } = useTranslation();
  const showNoDataMessage = !data || data.length === 0;

  return (
    <Box position="relative">
      <ResponsiveContainer width={240} height={292}>
        {showNoDataMessage ? (
          <Flex h="100%" alignItems="center">
            <p>{t("Not enough data to display the chart")}</p>
          </Flex>
        ) : (
          <Center>
            <PieChart width={210} height={270}>
              <Pie
                data={data}
                innerRadius={40}
                outerRadius={80}
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={entry.color}
                    width={163}
                    height={163}
                  />
                ))}
              </Pie>
              <Legend
                layout="vertical"
                align="center"
                iconType="circle"
                iconSize={9}
                width={240}
                formatter={(value: string) => {
                  let count;
                  if (data && data.length > 0) {
                    const item = data.find((item) => item.name === value);
                    if (item) {
                      count = item.count;
                    }
                  }
                  return (
                    <Flex justifyContent="space-between" display="inline-block">
                      <span
                        style={{
                          color: "black",
                          textTransform: "capitalize",
                        }}
                      >
                        {value.toLowerCase()}
                      </span>
                      <span
                        style={{
                          color: "black",
                          position: "absolute",
                          right: 0,
                          fontSize: "13px",
                        }}
                      >
                        {count}
                      </span>
                    </Flex>
                  );
                }}
                wrapperStyle={{ fontSize: "0.75rem" }}
              />
            </PieChart>
          </Center>
        )}
      </ResponsiveContainer>
    </Box>
  );
};
