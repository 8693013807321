import { ReactElement, useContext } from "react";
import { DashboardLayout } from "../../layout/DashboardLayout";
import {
  Flex,
  Input,
  InputGroup,
  InputRightAddon,
  Text,
} from "@chakra-ui/react";
import { BsLink45Deg } from "react-icons/bs";
import { CopyIcon } from "@chakra-ui/icons";
import { toast } from "react-hot-toast";

import { AuthContext } from "context/Auth";
import { useTranslation } from "react-i18next";

import { UsersList } from "./components/UsersList";

export const Users = (): ReactElement => {
  const { user, isSuperAdmin } = useContext(AuthContext);
  const { t } = useTranslation();

  const companyIdHash = user?.companyIdHash;
  const departmentIdHash = user?.departmentIdHash;
  const isDev = process.env.NODE_ENV === "development";

  const devLink = process.env.REACT_APP_DEV_LINK;
  const prodLink = process.env.REACT_APP_PROD_LINK;

  let registrationLink: string;

  if (!departmentIdHash) {
    registrationLink = isDev
      ? `${devLink}/register?companyId=${companyIdHash}`
      : `${prodLink}/register?companyId=${companyIdHash}`;
  } else {
    registrationLink = isDev
      ? `${devLink}/register?companyId=${companyIdHash}&departmentId=${departmentIdHash}`
      : `${prodLink}/register?companyId=${companyIdHash}&departmentId=${departmentIdHash}`;
  }

  return (
    <DashboardLayout>
      <>
        {!isSuperAdmin && (
          <Flex
            background="#ffffff"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            borderRadius="8px"
            w="400px"
            h="141px"
            gap="1.5rem"
          >
            <Flex gap="1rem" flexDirection="column">
              <Flex alignItems="center" gap="5px">
                <BsLink45Deg size="20px" />
                <Text fontWeight="bold">
                  {t("Your company registration link:")}
                </Text>
              </Flex>
              <InputGroup>
                <Input
                  disabled
                  value={registrationLink}
                  cursor="text !important"
                />
                <InputRightAddon
                  background="#c1b1f0"
                  color="#ffffff"
                  cursor="pointer !important"
                  onClick={() => {
                    navigator.clipboard.writeText(registrationLink);
                    toast.success(t("Link copied!"));
                  }}
                >
                  <CopyIcon />
                </InputRightAddon>
              </InputGroup>
            </Flex>
          </Flex>
        )}
        <UsersList />
      </>
    </DashboardLayout>
  );
};
