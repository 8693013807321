import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import "./theme/style.css";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import i18next from "i18next";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";

import { theme } from "./theme/theme";
import { toast } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./context/Auth";

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);
const link = createHttpLink({
  uri: process.env.REACT_APP_API_URI,
  credentials: "same-origin",
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("accessToken");
  const companyIdFromStorage = localStorage.getItem("companyId");
  const departmentIdFromStorage = localStorage.getItem("departmentId");
  const companyId = companyIdFromStorage
    ? JSON.parse(companyIdFromStorage)
    : null;
  const departmentId = departmentIdFromStorage
    ? JSON.parse(departmentIdFromStorage)
    : null;
  let headersToSend: { [key: string]: string } = {
    authorization: token ? `Bearer ${token}` : "",
  };
  switch (true) {
    case companyId !== null && departmentId !== null:
      headersToSend = {
        ...headersToSend,
        company: companyId,
        department: departmentId,
      };
      break;
    case companyId !== null:
      headersToSend = {
        ...headersToSend,
        company: companyId,
      };
      break;
    default:
      break;
  }
  return {
    headers: {
      ...headers,
      ...headersToSend,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // @ts-ignore
    graphQLErrors.forEach(({ error, message }, i) => {
      if (error?.statusCode === 400 || error?.statusCode === 403) {
        typeof error?.message === "string"
          ? toast.error(i18next.t(message))
          : toast.error(i18next.t(error.message[i]));
      }
      if (error?.statusCode === 401) {
        toast.error(i18next.t("Unhautorized"));
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userData");
        localStorage.removeItem("companyId");
        localStorage.removeItem("departmentId");
        localStorage.removeItem("chosenUser");
        localStorage.removeItem("chosenDepartment");
        localStorage.removeItem("chosenDepartmentId");
        localStorage.removeItem("chosenCompany");
        if (window.location.pathname !== "/login") {
          window.location.reload();
        }
      }
    });

    if (networkError) {
      if (typeof networkError === "string") {
        toast.error(i18next.t(networkError));
      }
    }
  }
});

const client = new ApolloClient({
  link: from([errorLink, authLink.concat(link)]),
  cache: new InMemoryCache(),
});

root.render(
  <ChakraProvider theme={theme}>
    <ApolloProvider client={client}>
      <ColorModeScript />
      <BrowserRouter>
        <AuthProvider>
          <App />
        </AuthProvider>
      </BrowserRouter>
    </ApolloProvider>
  </ChakraProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
