import { useMutation } from "@apollo/client";
import { LOGIN_USER } from "../graphql/queries";

export const useLogin = () => {
  const [loginUser, { data, error, loading }] = useMutation(LOGIN_USER);

  const handleLogin = (email: string, password: string) => {
    return loginUser({
      variables: {
        email,
        password,
      },
    });
  };

  return {
    handleLogin,
    data,
    error,
    loading,
  };
};
