import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Button, Flex, Heading, VStack } from "@chakra-ui/react";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  title: string;
  linkText?: string;
  children: ReactElement;
  hasLink?: boolean;
};

export const Section = ({
  title,
  hasLink = false,
  linkText = "View all",
  children,
}: Props): ReactElement => {
  const { t } = useTranslation();

  return (
    <VStack
      alignSelf="flex-start"
      align="flex-start"
      marginInlineStart="0 !important"
      w="100%"
      spacing={9}
    >
      <Flex w="100%" align="flex-start" alignSelf="start">
        <Heading color="#262626" fontSize="large">
          {t(title)}
        </Heading>
        <Button
          variant="link"
          color="#0032E2"
          rightIcon={<ArrowForwardIcon />}
          hidden={!hasLink}
        >
          {t(linkText)}
        </Button>
      </Flex>
      {children}
    </VStack>
  );
};
