import * as Yup from "yup";

export const registerAdminValidation = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(40, "Too Long!")
    .required("Required"),
  surname: Yup.string()
    .min(2, "Too Short!")
    .max(40, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .required("Required")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
  confirmationPassword: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("password")], "Passwords must match"),
  birthdate: Yup.date()
    .max(new Date(), "Birthdate must be in the past")
    .required("Required"),
  company: Yup.string()
    .matches(/^[a-zA-Z0-9]+$/, "Company must contain only letters and numbers")
    .required("Required"),
});
