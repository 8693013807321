import { Flex, HStack, VStack, useBreakpointValue } from "@chakra-ui/react";

import { CenteredContainer } from "components/CenteredContainer";
import { LateralNav } from "pages/dashboard/components/nav/LateralNav";
import { NavBar } from "components/NavBar";
import { ReactElement } from "react";

export const DashboardLayout = ({
  children,
}: {
  children: JSX.Element;
}): ReactElement => {
  const variantWidth = useBreakpointValue(
    {
      base: "90%",
      xl: "100%",
    },
    {
      fallback: "100%",
    }
  );

  return (
    <CenteredContainer hasPadding={false} isFullWidth>
      <HStack
        w="100%"
        background="gray.50"
        align="flex-start"
        position="relative"
        overflow="auto"
      >
        <LateralNav />
        <VStack pl={4} pb={5} w="100%" maxW={variantWidth} maxH="100vh">
          <NavBar />
          <Flex flexDir="column" w="100%" gap="1.5rem" mt="2rem" p={5}>
            {children}
          </Flex>
        </VStack>
      </HStack>
    </CenteredContainer>
  );
};
