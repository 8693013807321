import { Form, Formik } from "formik";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { LoginInput } from "pages/login/components/LoginInput";
import { confirmValidation } from "components/forms/validation/confirmValidation";
import { CONFIRM_REGISTRATION } from "graphql/queries";
import { ReactElement } from "react";

interface ConfirmRegistrationModalProps {
  isModalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  email: string;
}

const initialValues = {
  code: "",
};

export const ConfirmRegistrationModal = ({
  isModalOpen,
  setModalOpen,
  email,
}: ConfirmRegistrationModalProps): ReactElement => {
  const { t } = useTranslation();
  const [confirmRegistration] = useMutation(CONFIRM_REGISTRATION);
  const navigate = useNavigate();

  const handleSubmit = (val: { code: string }) => {
    confirmRegistration({
      variables: {
        confirmRegistrationInput: { email, confirmationCode: val.code },
      },
    })
      .then((r) => {
        toast.success(t("Email confirmed. Now you can login."), {
          duration: 4000,
        });
        navigate("/login");
        setModalOpen(false);
      })
      .catch((e) => {
        toast.error(t(e.message), { duration: 4000 });
      });
  };

  return (
    <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
      <ModalOverlay />
      <ModalContent p={5}>
        <ModalHeader>{t("Confirm registration")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            {t(
              "We have sent a confirmation code to your email. Enter it below to confirm your account."
            )}
          </Box>
        </ModalBody>
        <Formik
          onSubmit={(val) => {
            handleSubmit(val);
          }}
          initialValues={initialValues}
          validationSchema={confirmValidation}
        >
          <Form style={{ padding: "1.5rem" }}>
            <LoginInput
              name="code"
              placeholder="Code from email"
              type="text"
              autocomplete="new-password"
            />

            <Button mr={3} type="submit">
              {t("Confirm")}
            </Button>
          </Form>
        </Formik>
      </ModalContent>
    </Modal>
  );
};
