import { Form, Formik } from "formik";
import { Button, Link, Text } from "@chakra-ui/react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { ReactElement, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { LoginInput } from "pages/login/components/LoginInput";
import { registerValidation } from "components/forms/validation/registerValidation";
import { useRegister } from "hooks/useRegister";
import { useRegisterManager } from "hooks/useRegisterManager";
import { ConfirmRegistrationModal } from "components/forms/modals/ConfirmRegistrationModal";

interface RegisterFormValues {
  name: string;
  surname: string;
  department?: string;
  birthdate: string;
  email: string;
  password: string;
}

const initialValues = {
  name: "",
  surname: "",
  department: "",
  birthdate: "",
  email: "",
  password: "",
  confirmationPassword: "",
};

export const RegisterForm = (): ReactElement => {
  const { t } = useTranslation();
  const { handleRegister } = useRegister();
  const { handleRegisterManager } = useRegisterManager();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const companyId = searchParams.get("companyId") || "";
  const departmentId = searchParams.get("departmentId") || "";

  const handleSubmit = (
    values: RegisterFormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const { name, surname, department, birthdate, email, password } = values;

    setSubmitting(true);
    setNewEmail(email);
    if (department) {
      handleRegisterManager(
        email,
        password,
        name,
        surname,
        birthdate,
        companyId,
        department
      )
        .then(() => {
          toast.success(t("Manager created, please verify your mail!"), {
            duration: 4000,
          });
          setSubmitting(false);
          setIsModalOpen(true);
        })
        .catch((e) => {
          toast.error(t(e.message), { duration: 4000 });
          setSubmitting(false);
        });
    } else {
      handleRegister(
        email,
        password,
        name,
        surname,
        birthdate,
        companyId,
        departmentId
      )
        .then(() => {
          toast.success(t("User created, please verify your mail!"), {
            duration: 4000,
          });
          setSubmitting(false);
          setIsModalOpen(true);
        })
        .catch((e) => {
          toast.error(t(e.message), { duration: 4000 });
          setSubmitting(false);
        });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values, setSubmitting);
      }}
      validationSchema={
        departmentId ? registerValidation(false) : registerValidation(true)
      }
    >
      {({ isSubmitting }) => (
        <Form>
          <ConfirmRegistrationModal
            isModalOpen={isModalOpen}
            setModalOpen={setIsModalOpen}
            email={newEmail}
          />

          <LoginInput name="name" placeholder="Name" />
          <LoginInput name="surname" placeholder="Surname" />
          {!departmentId && (
            <LoginInput name="department" placeholder="Department" />
          )}
          <LoginInput name="birthdate" placeholder="Birthdate" type="date" />
          <LoginInput name="email" placeholder="Email" />
          <LoginInput name="password" placeholder="Password" type="password" />
          <LoginInput
            name="confirmationPassword"
            placeholder="Repeat password"
            type="password"
          />

          <Button
            fontSize="sm"
            width="100%"
            mt={4}
            type="submit"
            mb={3}
            isLoading={isSubmitting}
          >
            {t("Sign up")}
          </Button>
          <Text fontSize="sm">
            {t("Already have the verification code?")}
            <Button
              fontFamily="Lato"
              fontSize="sm"
              fontWeight="bold"
              color="brand.500"
              onClick={() => setIsModalOpen(true)}
              variant="text"
              mb="0.25rem"
              ml={1}
            >
              {t("Enter the code")}
            </Button>
          </Text>
          <Link
            fontFamily="Lato"
            fontSize="sm"
            fontWeight="bold"
            color="brand.500"
            onClick={() => navigate("/login")}
          >
            {t("Login here")}
          </Link>
        </Form>
      )}
    </Formik>
  );
};
