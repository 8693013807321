import { Box, useBreakpointValue } from "@chakra-ui/react";
import { ReactElement } from "react";

type Props = {
  children: JSX.Element;
  hasPadding?: boolean;
  alignItems?: string;
  isFullWidth?: boolean;
};

export const CenteredContainer = ({
  children,
  hasPadding = true,
  alignItems,
  isFullWidth = false,
}: Props): ReactElement => {
  const variantHeight = useBreakpointValue(
    {
      base: "100vh",
      //   for the pages aligned to the top (Survey) - use the whole height of the screen
      "2xl": alignItems === "baseline" ? "100vh" : "100%",
    },
    {
      fallback: "base",
    }
  );
  const variantWidth = useBreakpointValue(
    {
      base: "100vw",
      "2xl": isFullWidth ? "100vw" : "1560px",
    },
    {
      fallback: "base",
    }
  );
  return (
    <Box
      position="relative"
      minH="100vh"
      maxH="100vh"
      w="100vw"
      display="flex"
      alignItems={alignItems ? alignItems : "center"}
      justifyContent="center"
    >
      <Box
        height={variantHeight}
        p={hasPadding ? 4 : 0}
        background="white"
        borderRadius="xl"
        display="flex"
        w={variantWidth}
        overflow="auto"
      >
        {children}
      </Box>
    </Box>
  );
};
