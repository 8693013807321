import { DashboardLayout } from "../../layout/DashboardLayout";
import { ReactElement, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Flex,
  HStack,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useQuery } from "@apollo/client";

import { GET_RESULTS, GET_USER_RESULTS } from "graphql/queries";

import { InfoCard } from "components/InfoCard";
import { Section } from "pages/dashboard/components/main/Section";
import { Radial } from "pages/dashboard/components/main/graphs/Radial";
import { Bar } from "pages/dashboard/components/main/graphs/Bar";
import { TestCard } from "pages/dashboard/components/main/TestCard";
import { PersonalityGraph } from "pages/dashboard/components/main/graphs/PersonalityGraph";
import { colors } from "pages/dashboard/components/main/graphs/graphTypes";
import { PerspectiveGraph } from "pages/dashboard/components/main/graphs/PerspectiveGraph";

import { AuthContext } from "context/Auth";
import { LoadingPage } from "pages/loading/LoadingPage";

export const Dashboard = (): ReactElement => {
  const { user } = useContext(AuthContext);
  const location = useLocation();

  const { state } = location;

  if (state) {
    localStorage.setItem("chosenUser", state.name);
  }

  const userId = location.pathname.includes("users")
    ? location.pathname.replace("/users/", "")
    : undefined;

  type QueryVariables = {
    sub: string;
  };

  const { loading, data, refetch } = useQuery<QueryVariables>(
    userId ? GET_USER_RESULTS : GET_RESULTS,
    {
      fetchPolicy: "network-only",
      variables: userId ? { sub: userId } : undefined,
    }
  );

  type ChartsData = {
    resultsDashboardCurrentUser?: {
      __typename: string | undefined;
      totalTimeSpent: string;
      charts: {
        __typename: string;
        groupName: string;
        groupId: string;
        styles: { styleName: string; average: number }[];
      }[];
    };
    resultsDashboardClickedUser?: {
      __typename: string;
      totalTimeSpent: string;
      charts: {
        __typename: string;
        groupName: string;
        groupId: string;
        styles: { styleName: string; average: number }[];
      }[];
    };
  };

  const [chartsData, setChartsData] = useState<ChartsData>();

  useEffect(() => {
    if (data) {
      setChartsData(data as ChartsData);
    }

    // fetch after survey is done
    if (user?.isSurveyDone) {
      refetch().then((d) => {
        setChartsData(d.data as ChartsData);
      });
    }
  }, [data, loading, user, refetch]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("chosenUser");
    };
  }, []);

  const findAndMapGraph = (name: string) => {
    let charts = userId
      ? chartsData?.resultsDashboardClickedUser?.charts
      : chartsData?.resultsDashboardCurrentUser?.charts;
    let chart = charts?.find((e) => e.groupId === name);

    if (!chart) return undefined;

    return chart.styles.map((style, index) => ({
      name:
        style.styleName.charAt(0).toUpperCase() +
        style.styleName.slice(1).toLowerCase(),
      value: style.average,
      color: name === "G06" ? colors[index + 2] : colors[index],
    }));
  };

  const variantWrap = useBreakpointValue(
    {
      base: "wrap",
      "2xl": "nowrap",
    },
    {
      fallback: "wrap",
    }
  );

  return (
    <DashboardLayout>
      <>
        {loading ? (
          <LoadingPage />
        ) : (
          <Box maxW="100%">
            <Flex
              flexDirection="column"
              rowGap={10}
              // @ts-ignore
              flexWrap={variantWrap}
            >
              <VStack
                spacing={5}
                justify="flex-start"
                alignItems="flex-start"
                maxW="1250px"
                w="100%"
              >
                <HStack spacing={5}>
                  <InfoCard
                    mainText="Time spent"
                    subText={
                      userId
                        ? chartsData?.resultsDashboardClickedUser
                            ?.totalTimeSpent
                        : chartsData?.resultsDashboardCurrentUser
                            ?.totalTimeSpent
                    }
                  />
                </HStack>
                <Section title="Test results">
                  {/* @ts-ignore */}
                  <Flex flexWrap="wrap" maxW="1250px" w="100%" gap="57px">
                    <TestCard title="Sensory channel">
                      <Radial data={findAndMapGraph("G01")} />
                    </TestCard>

                    <Flex
                      flexDirection="column"
                      justifyContent="space-between"
                      gap={3}
                    >
                      <TestCard title="Reference" width="400px" height="152px">
                        <Bar data={findAndMapGraph("G06")} />
                      </TestCard>
                      <TestCard
                        title="Orientation"
                        width="400px"
                        height="152px"
                      >
                        <Bar data={findAndMapGraph("G05")} />
                      </TestCard>
                    </Flex>
                    <TestCard title="Perspective" width="400px" height="360px">
                      <PerspectiveGraph data={findAndMapGraph("G03")} />
                    </TestCard>
                  </Flex>
                </Section>
              </VStack>

              <Section title="Overview">
                <TestCard
                  title="Primary interests"
                  iconColor="black"
                  fontSize="lg"
                >
                  <PersonalityGraph data={findAndMapGraph("G02")} />
                </TestCard>
              </Section>
            </Flex>
          </Box>
        )}
      </>
    </DashboardLayout>
  );
};
