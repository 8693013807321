import { Flex, Spinner } from "@chakra-ui/react";
import { ReactElement } from "react";

export const LoadingPage = (): ReactElement => {
  return (
    <Flex
      h="100vh"
      w="100%"
      alignItems="flex-start"
      justifyContent="center"
      marginTop="50px"
    >
      <Spinner size="xl" />
    </Flex>
  );
};
