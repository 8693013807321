import React, { ReactElement } from "react";
import { Button, HStack, Spacer, VStack } from "@chakra-ui/react";
import { ArrowForwardIcon, ArrowBackIcon } from "@chakra-ui/icons";
import { Form, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

interface FormStepperProps {
  questionNumber: number;
  nQuestions: number;
  setCurrentStep: (step: number) => void;
  children: ReactElement;
}

export const FormStepper = ({
  questionNumber,
  nQuestions,
  setCurrentStep,
  children,
}: FormStepperProps): ReactElement => {
  const isLastQuestion = questionNumber === nQuestions;
  const { validateForm, errors } = useFormikContext();
  const { t } = useTranslation();

  return (
    <Form>
      <VStack spacing={10} justify="center" h="100%" minH="38rem" pb={10}>
        {children}
        <HStack w="30rem" justify="space-between" mt="0px">
          {questionNumber === 1 ? (
            <Spacer />
          ) : (
            <Button
              onClick={() => setCurrentStep(questionNumber - 1)}
              leftIcon={<ArrowBackIcon />}
              fontFamily="Lato"
              fontSize="xs"
            >
              {t("Previous question")}
            </Button>
          )}

          <Button
            onClick={async () => {
              const validationErrors = await validateForm();

              if (Object.keys(validationErrors).length > 0) {
                return;
              }
              if (nQuestions !== questionNumber) {
                setCurrentStep(questionNumber + 1);
              }
            }}
            rightIcon={<ArrowForwardIcon />}
            fontFamily="Lato"
            fontSize="xs"
            type={isLastQuestion ? "submit" : "button"}
            minW="7.3rem"
            isDisabled={Object.keys(errors).length > 0}
          >
            {isLastQuestion ? t("Submit") : t("Next question")}
          </Button>
        </HStack>
      </VStack>
    </Form>
  );
};
