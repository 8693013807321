import { Form, Formik } from "formik";
import { Button, Link, Text } from "@chakra-ui/react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { FORGOT_PASSWORD } from "graphql/queries";

import { LoginInput } from "pages/login/components/LoginInput";
import { ChangePasswordModal } from "components/forms/modals/ChangePasswordModal";
import { emailValidation } from "components/forms/validation/emailValidation";
import { styles } from "components/forms/styles/formStyles";

type ForgotPasswordFormValues = {
  email: string;
};

const initialValues: ForgotPasswordFormValues = {
  email: "",
};

export const ForgotPasswordForm = (): ReactElement => {
  const [forgotPassword] = useMutation(FORGOT_PASSWORD);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { fontFamily, fontSize, fontWeight, color } = styles;

  const handleSubmit = (
    values: ForgotPasswordFormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setEmail(values.email);
    forgotPassword({
      variables: {
        forgotPasswordInput: {
          email: values.email,
        },
      },
    })
      .then(() => {
        toast.success(t("Email for reset password was sent."), {
          duration: 4000,
        });
        setIsModalOpen(true);
        setSubmitting(false);
      })
      .catch((e) => {
        toast.error(t(e.message), { duration: 4000 });
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values, setSubmitting);
      }}
      validationSchema={emailValidation}
    >
      <Form>
        <ChangePasswordModal
          isModalOpen={isModalOpen}
          setModalOpen={setIsModalOpen}
          email={email}
          onEmailSet={setEmail}
        />
        <Text mb="1rem" mt="2rem">
          {t("Reset password in two quick steps")}
        </Text>
        <LoginInput placeholder={t("Your Email") || ""} name="email" />

        <Button fontSize={fontSize} width="100%" mt={6} mb={3} type="submit">
          {t("Reset")}
        </Button>

        <Text fontSize={fontSize}>
          {t("Back to login?")}
          <Link
            fontFamily={fontFamily}
            fontSize={fontSize}
            fontWeight={fontWeight}
            color={color}
            ml={1}
            onClick={() => navigate("/login")}
          >
            {t("Login here")}
          </Link>
        </Text>
        <Text fontSize={fontSize}>
          {t("Already have the verification code?")}
          <Button
            fontFamily={fontFamily}
            fontSize={fontSize}
            fontWeight={fontWeight}
            color={color}
            onClick={() => setIsModalOpen(true)}
            variant="text"
            mb="0.25rem"
            ml={1}
          >
            {t("Enter the code")}
          </Button>
        </Text>
      </Form>
    </Formik>
  );
};
