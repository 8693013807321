import { Box, Divider, Heading, VStack } from "@chakra-ui/react";
import { NavButton } from "./NavButton";
import { BsGrid, BsStar, BsHouse, BsGear } from "react-icons/bs";
import { ReactElement, useContext } from "react";

import { useTranslation } from "react-i18next";
import { AuthContext } from "context/Auth";

export const LateralNav = (): ReactElement => {
  const { isAdmin, isManager } = useContext(AuthContext);
  const { t } = useTranslation();

  return (
    <VStack
      zIndex={99999}
      minW="12.5rem"
      height="100vh"
      position="sticky"
      top="0"
      left="0"
      align="flex-start"
      justify="space-between"
      background="white"
      borderRadius="lg"
      pt={5}
      pb={5}
      overflow="auto"
    >
      <Box width="100%">
        <Heading
          color="#987DE6"
          fontFamily="Nunito"
          fontWeight={700}
          fontSize="lg"
          opacity={0.7}
          mb={20}
          p={6}
        >
          KOSKO
        </Heading>
        <VStack spacing={0}>
          <NavButton
            icon={BsHouse}
            text="Home"
            path="/dashboard"
            highlightForSuperAdmin
          />
          {(isAdmin || isManager) && (
            <NavButton
              icon={BsGrid}
              text={isAdmin ? "Dipartimenti" : t("Users")}
              path="/users"
            />
          )}
          {isManager && (
            <NavButton icon={BsStar} text="I miei risultati" path="/results" />
          )}
        </VStack>
      </Box>
      <VStack width="100%">
        <Divider borderWidth={1.5} width="80%" />
        <NavButton icon={BsGear} text={t("Settings")} path="/settings" />
      </VStack>
    </VStack>
  );
};
