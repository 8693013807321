import { Heading, VStack } from "@chakra-ui/react";

import { Answer } from "pages/survey/components/Answer";
import { alphabet } from "utils/characters";
import { SurveyAnswer } from "pages/survey/Survey";
import { ReactElement } from "react";

type Props = {
  title: string;
  answers: SurveyAnswer[];
  questionNumber: number;
};

export const Question = ({
  title,
  answers,
  questionNumber = 1,
}: Props): ReactElement => {
  return (
    <VStack spacing={10} justify="center" h="100%" pb={10} maxW="50rem">
      <Heading fontFamily="Roboto" fontWeight={700} fontSize="2xl">
        {`${questionNumber}. ${title}`}
      </Heading>

      {answers.map((a, i) => (
        <Answer
          key={a.id}
          values={a.values}
          title={`${alphabet[i]}. ${a.answerName}`}
          answerId={a.id}
          styleId={a.style.id}
        />
      ))}
    </VStack>
  );
};
