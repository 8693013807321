export type GraphData = {
  data?: {
    name: string;
    value: number;
    color?: string;
    count?: number;
    total?: number;
    fullMark?: number;
  }[];
};

export const colors = ["#ee9daa", "#bde491", "#52a6df", "#ad97eb"];

export const graphColors = {
  purple: "#ad97eb",
  blue: "#52a6df",
  red: "#ee9daa",
  green: "#bde491",
};

export const adminDashboardColors: { id: string; colors: string[] }[] = [
  { id: "G01", colors: ["#87c0d1", "#d6cbf5", "#f8d8dd", "#bde491"] },
  {
    id: "G02",
    colors: ["#87c0d1", "#bde491", "#ad97eb", "#ee9daa", "#74b8e5", "#ecca9c"],
  },
  { id: "G03", colors: ["#ee9daa", "#bde491", "#ecca9c", "#74b8e5"] },
  { id: "G05", colors: ["#f1b1bb", "#b7d9e3", "#d6cbf5"] },
  { id: "G06", colors: ["#f0d5b0", "#b5c8f1", "#d6cbf5"] },
];
