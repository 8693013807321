import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  HStack,
  Text,
  Stack,
  StackDirection,
} from "@chakra-ui/react";

import { hexToRGB } from "helpers/helpers";
import { ReactElement, useContext } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "context/Auth";

type Props = {
  name: string;
  value: number;
  color: string;
  count?: number;
  total?: number;
  hasLegend?: boolean;
};

export const Circular = ({
  name,
  value,
  color,
  count,
  total,
  hasLegend,
}: Props): ReactElement => {
  const { isAdmin, isManager } = useContext(AuthContext);
  const { pathname } = useLocation();

  let progressSize;
  let progressWidthHeight;
  let progressTopLeft;
  let grahpDirection: StackDirection | undefined = undefined;
  let graphWidth;

  switch (true) {
    case name === "test":
      progressSize = "100px";
      progressWidthHeight = "76px";
      progressTopLeft = "12px";
      grahpDirection = "column";
      graphWidth = "100px";
      break;
    case pathname === "/dashboard" && (isManager || isAdmin):
      progressSize = "78px";
      progressWidthHeight = "58px";
      progressTopLeft = "10px";
      grahpDirection = "row";
      graphWidth = "170px";
      break;
    default:
      progressSize = "90px";
      progressWidthHeight = "68px";
      progressTopLeft = "11px";
      grahpDirection = "column";
      graphWidth = "90px";
      break;
  }

  // to be cleaned up with a responsive graph with same color bar/bg
  // works only with fixed size
  return (
    <Stack
      align="center"
      direction={grahpDirection}
      width={graphWidth}
      justifyContent="space-between"
    >
      {hasLegend && name && (
        <HStack>
          <Box w="8px" h="8px" borderRadius="full" background={color}></Box>
          <Text
            fontFamily="DM Sans"
            fontSize="0.75rem"
            textTransform="capitalize"
          >
            {name}
          </Text>
        </HStack>
      )}

      <CircularProgress
        color={color}
        trackColor={hexToRGB(color, "0.2")}
        capIsRound
        value={value}
        position="relative"
        size={progressSize}
        thickness={9}
      >
        <Box
          borderRadius="full"
          background={hexToRGB(color, "0.2")}
          w={progressWidthHeight}
          h={progressWidthHeight}
          position="absolute"
          top={progressTopLeft}
          left={progressTopLeft}
        ></Box>
        <CircularProgressLabel fontSize="12px" fontFamily="DM Sans">
          {total ? `${count}/${total}` : `${value.toFixed()}%`}
        </CircularProgressLabel>
      </CircularProgress>
    </Stack>
  );
};
