import { BsSearch, BsBell } from "react-icons/bs";
import {
  Avatar,
  Box,
  Divider,
  HStack,
  Heading,
  IconButton,
  Text,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";
import { ReactElement, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AuthContext } from "context/Auth";
import { pathNames } from "constants/paths";

export const NavBar = (): ReactElement => {
  const { pathname } = useLocation();
  const { user, isAdmin, isSuperAdmin } = useContext(AuthContext);

  const chosenUser = localStorage.getItem("chosenUser");
  const chosenDepartment = localStorage.getItem("chosenDepartment");
  const chosenDepartmentId = localStorage.getItem("chosenDepartmentId");
  const chosenCompany = localStorage.getItem("chosenCompany");

  const { t } = useTranslation();

  return (
    <HStack
      justify="space-between"
      alignSelf="flex-start"
      w="100%"
      maxW="1290px"
      h={20}
      position="sticky"
      top="0"
      background="gray.50"
      zIndex="10"
      p={5}
      pt={12}
    >
      <Heading fontFamily="Mulish" fontSize="lg">
        {((chosenUser || chosenDepartment) && pathname.includes("/users/")) ||
        (isSuperAdmin && pathname.includes("/users")) ? (
          <Breadcrumb fontWeight="medium" fontSize="16px">
            <BreadcrumbItem color="#999999">
              <BreadcrumbLink href={isSuperAdmin ? "/companies" : "/users"}>
                {pathname.includes("/users") && isAdmin
                  ? "Dipartimenti"
                  : isSuperAdmin
                  ? "Home"
                  : "Utenti"}
              </BreadcrumbLink>
            </BreadcrumbItem>
            {isSuperAdmin && (
              <BreadcrumbItem
                color={
                  (isAdmin && pathname.includes("/users/departments/")) ||
                  (isSuperAdmin && pathname === "/users")
                    ? "#262626"
                    : "#999999"
                }
                isCurrentPage={
                  (isAdmin && pathname.includes("/users/departments/")) ||
                  (isSuperAdmin && pathname === "/users")
                }
              >
                <BreadcrumbLink href="/users">{chosenCompany}</BreadcrumbLink>
              </BreadcrumbItem>
            )}
            {(isAdmin ||
              (isSuperAdmin && pathname.includes("/users/departments/"))) && (
              <BreadcrumbItem
                isCurrentPage={pathname.includes("/users/departments/")}
                color={chosenUser ? "#999999" : "#262626"}
              >
                <BreadcrumbLink
                  href={`/users/departments/${chosenDepartmentId}`}
                >
                  {chosenDepartment}
                </BreadcrumbLink>
              </BreadcrumbItem>
            )}
            {chosenUser && !pathname.includes("/departments/") && (
              <BreadcrumbItem isCurrentPage>
                <BreadcrumbLink>{chosenUser}</BreadcrumbLink>
              </BreadcrumbItem>
            )}
          </Breadcrumb>
        ) : pathname === "/users" && isAdmin ? (
          "Dipartimenti"
        ) : (
          t(pathNames[pathname])
        )}
      </Heading>
      <HStack spacing={2}>
        <IconButton
          aria-label="search"
          variant="ghost"
          icon={<BsSearch />}
          color="gray.400"
        />
        <IconButton
          variant="ghost"
          aria-label="notification"
          icon={<BsBell />}
          color="gray.400"
          size="lg"
        />
        <Box h={10}>
          <Divider orientation="vertical" borderWidth={1.5} mr={5} />
        </Box>
        <Link to="/profile">
          <HStack>
            <Text>
              {user?.name} {user?.surname}
            </Text>
            <Avatar size="sm" />
          </HStack>
        </Link>
      </HStack>
    </HStack>
  );
};
