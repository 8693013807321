/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation RegisterUser(\n    $email: String!\n    $name: String!\n    $surname: String!\n    $password: String!\n    $birthdate: String!\n  ) {\n    registerUser(\n      registerUserInput: {\n        email: $email\n        name: $name\n        surname: $surname\n        password: $password\n        birthdate: $birthdate\n      }\n    )\n  }\n": types.RegisterUserDocument,
    "\n  mutation RegisterUserWithDepartmentId(\n    $email: String!\n    $name: String!\n    $surname: String!\n    $password: String!\n    $birthdate: String!\n    $companyId: String!\n    $departmentId: String!\n  ) {\n    registerUserWithDepartmentId(\n      regWithDepartmentInput: {\n        email: $email\n        name: $name\n        surname: $surname\n        password: $password\n        birthdate: $birthdate\n        companyId: $companyId\n        departmentId: $departmentId\n      }\n    )\n  }\n": types.RegisterUserWithDepartmentIdDocument,
    "\n  mutation CreateDepartmentWithManagerUser(\n    $email: String!\n    $name: String!\n    $surname: String!\n    $password: String!\n    $birthdate: String!\n    $companyId: String!\n    $departmentName: String!\n  ) {\n    createDepartmentWithManagerUser(\n      regWithDepartmentInput: {\n        email: $email\n        name: $name\n        surname: $surname\n        password: $password\n        birthdate: $birthdate\n        companyId: $companyId\n        departmentName: $departmentName\n      }\n    ) {\n      departmentName\n      id\n    }\n  }\n": types.CreateDepartmentWithManagerUserDocument,
    "\n  mutation CreateCompanyWithAdminUser(\n    $email: String!\n    $name: String!\n    $surname: String!\n    $password: String!\n    $birthdate: String!\n    $companyName: String!\n  ) {\n    createCompanyWithAdminUser(\n      input: {\n        email: $email\n        name: $name\n        surname: $surname\n        password: $password\n        birthdate: $birthdate\n        companyName: $companyName\n      }\n    ) {\n      companyName\n      id\n    }\n  }\n": types.CreateCompanyWithAdminUserDocument,
    "\n  mutation ConfirmPassword($confirmPasswordInput: ConfirmPasswordInput!) {\n    confirmPassword(confirmPasswordInput: $confirmPasswordInput)\n  }\n": types.ConfirmPasswordDocument,
    "\n  mutation LoginUser($email: String!, $password: String!) {\n    loginUser(loginUserInput: { email: $email, password: $password }) {\n      accessToken\n      refreshToken\n    }\n  }\n": types.LoginUserDocument,
    "\n  mutation ConfirmRegistration(\n    $confirmRegistrationInput: ConfirmRegistartionInput!\n  ) {\n    confirmRegistration(confirmRegistartionInput: $confirmRegistrationInput)\n  }\n": types.ConfirmRegistrationDocument,
    "\n  mutation ForgotPassword($forgotPasswordInput: ForgotPasswordInput!) {\n    forgotPassword(forgotPasswordInput: $forgotPasswordInput)\n  }\n": types.ForgotPasswordDocument,
    "\n  mutation UpdateQuestions($questions: [UpdateQuestionInput!]!) {\n    updateQuestions(questions: $questions) {\n      id\n      group {\n        groupName\n      }\n      groupId\n      questionId\n      questionName\n      isInSurvey\n    }\n  }\n": types.UpdateQuestionsDocument,
    "\n  query GetAllCompanies{\n    getAllCompanies{\n      name\n      surname\n      company {\n        id\n        companyName\n      }\n    }\n  }\n": types.GetAllCompaniesDocument,
    "\n  mutation CreateTest($createTestInput: CreateTestInput!) {\n    createTest(createTestInput: $createTestInput) {\n      id\n      userId\n      answers {\n        answerId\n        value\n        duration\n      }\n      timeStart\n      timeEnd\n      timeSpent\n      createdAt\n    }\n  }\n": types.CreateTestDocument,
    "\n  query GET_AUTH {\n    getAuth\n  }\n": types.Get_AuthDocument,
    "\n  query GET_QUESTIONS {\n    questions {\n      id\n      group {\n        groupName\n      }\n      groupId\n      questionId\n      questionName\n      isInSurvey\n    }\n  }\n": types.Get_QuestionsDocument,
    "\n  query GET_RESULTS {\n    resultsDashboardCurrentUser {\n      totalTimeSpent\n      charts {\n        groupName\n        groupId\n\n        styles {\n          styleName\n          average\n        }\n      }\n    }\n  }\n": types.Get_ResultsDocument,
    "\n  query GET_SURVEY {\n    questionsSurvey {\n      id\n      group {\n        groupName\n      }\n      groupId\n      questionId\n      questionName\n      isInSurvey\n      answers {\n        answerId\n        answerName\n        id\n        values\n        style {\n          id\n        }\n      }\n      group {\n        groupId\n        groupName\n        groupDescription\n      }\n      questionId\n      questionName\n    }\n  }\n": types.Get_SurveyDocument,
    "\n  query RESULTS_ADMIN_DASHBOARD {\n    resultsAdminDashboard {\n      totalUsers\n      finishedSurveys\n      percentageOfCompletedSurveys\n    }\n  }\n": types.Results_Admin_DashboardDocument,
    "\n  query ADMIN_DASHBOARD_CHARTS($year: Float!) {\n    resultsAdminDashboardCharts(year: $year) {\n      groupId\n      styles {\n        id\n        styleName\n        groupId\n        avarage\n        count\n      }\n      mixed\n    }\n  }\n": types.Admin_Dashboard_ChartsDocument,
    "\n  query RESULTS_MANAGER_DASHBOARD($departmentId: String!) {\n    resultsManagerDashboard(departmentId: $departmentId) {\n      totalUsers\n      finishedSurveys\n      percentageOfCompletedSurveys\n    }\n  }\n": types.Results_Manager_DashboardDocument,
    "\n  query MANAGER_DASHBOARD_CHARTS($year: Float!, $departmentId: String!) {\n    resultsManagerDashboardCharts(year: $year, departmentId: $departmentId) {\n      groupId\n      styles {\n        id\n        styleName\n        groupId\n        avarage\n        count\n      }\n      mixed\n    }\n  }\n": types.Manager_Dashboard_ChartsDocument,
    "\n  query GET_STYLES {\n    styles {\n      styleName\n    }\n  }\n": types.Get_StylesDocument,
    "\n  query GET_USER {\n    me {\n      sub\n      name\n      surname\n      birthdate\n      email\n      roles\n      isSurveyDone\n      companyIdHash\n      departmentIdHash\n    }\n  }\n": types.Get_UserDocument,
    "\n  query GET_DEPARTMENT_LIST($companyId: String!) {\n    companyDepartmentList(companyId: $companyId) {\n      departmentId\n      departmentName\n      managerName\n      companyName\n    }\n  }\n": types.Get_Department_ListDocument,
    "\n  query GET_USER_LIST($departmentId: String!, $managerId: String) {\n    departmentUsersList(departmentId: $departmentId, managerId: $managerId) {\n      users {\n        sub\n        name\n        surname\n        email\n      }\n      departmentName\n    }\n  }\n": types.Get_User_ListDocument,
    "\n  query GET_USER_RESULTS($sub: String!) {\n    resultsDashboardClickedUser(sub: $sub) {\n      totalTimeSpent\n      charts {\n        groupName\n        groupId\n\n        styles {\n          styleName\n          average\n        }\n      }\n    }\n  }\n": types.Get_User_ResultsDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RegisterUser(\n    $email: String!\n    $name: String!\n    $surname: String!\n    $password: String!\n    $birthdate: String!\n  ) {\n    registerUser(\n      registerUserInput: {\n        email: $email\n        name: $name\n        surname: $surname\n        password: $password\n        birthdate: $birthdate\n      }\n    )\n  }\n"): (typeof documents)["\n  mutation RegisterUser(\n    $email: String!\n    $name: String!\n    $surname: String!\n    $password: String!\n    $birthdate: String!\n  ) {\n    registerUser(\n      registerUserInput: {\n        email: $email\n        name: $name\n        surname: $surname\n        password: $password\n        birthdate: $birthdate\n      }\n    )\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RegisterUserWithDepartmentId(\n    $email: String!\n    $name: String!\n    $surname: String!\n    $password: String!\n    $birthdate: String!\n    $companyId: String!\n    $departmentId: String!\n  ) {\n    registerUserWithDepartmentId(\n      regWithDepartmentInput: {\n        email: $email\n        name: $name\n        surname: $surname\n        password: $password\n        birthdate: $birthdate\n        companyId: $companyId\n        departmentId: $departmentId\n      }\n    )\n  }\n"): (typeof documents)["\n  mutation RegisterUserWithDepartmentId(\n    $email: String!\n    $name: String!\n    $surname: String!\n    $password: String!\n    $birthdate: String!\n    $companyId: String!\n    $departmentId: String!\n  ) {\n    registerUserWithDepartmentId(\n      regWithDepartmentInput: {\n        email: $email\n        name: $name\n        surname: $surname\n        password: $password\n        birthdate: $birthdate\n        companyId: $companyId\n        departmentId: $departmentId\n      }\n    )\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateDepartmentWithManagerUser(\n    $email: String!\n    $name: String!\n    $surname: String!\n    $password: String!\n    $birthdate: String!\n    $companyId: String!\n    $departmentName: String!\n  ) {\n    createDepartmentWithManagerUser(\n      regWithDepartmentInput: {\n        email: $email\n        name: $name\n        surname: $surname\n        password: $password\n        birthdate: $birthdate\n        companyId: $companyId\n        departmentName: $departmentName\n      }\n    ) {\n      departmentName\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CreateDepartmentWithManagerUser(\n    $email: String!\n    $name: String!\n    $surname: String!\n    $password: String!\n    $birthdate: String!\n    $companyId: String!\n    $departmentName: String!\n  ) {\n    createDepartmentWithManagerUser(\n      regWithDepartmentInput: {\n        email: $email\n        name: $name\n        surname: $surname\n        password: $password\n        birthdate: $birthdate\n        companyId: $companyId\n        departmentName: $departmentName\n      }\n    ) {\n      departmentName\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateCompanyWithAdminUser(\n    $email: String!\n    $name: String!\n    $surname: String!\n    $password: String!\n    $birthdate: String!\n    $companyName: String!\n  ) {\n    createCompanyWithAdminUser(\n      input: {\n        email: $email\n        name: $name\n        surname: $surname\n        password: $password\n        birthdate: $birthdate\n        companyName: $companyName\n      }\n    ) {\n      companyName\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CreateCompanyWithAdminUser(\n    $email: String!\n    $name: String!\n    $surname: String!\n    $password: String!\n    $birthdate: String!\n    $companyName: String!\n  ) {\n    createCompanyWithAdminUser(\n      input: {\n        email: $email\n        name: $name\n        surname: $surname\n        password: $password\n        birthdate: $birthdate\n        companyName: $companyName\n      }\n    ) {\n      companyName\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ConfirmPassword($confirmPasswordInput: ConfirmPasswordInput!) {\n    confirmPassword(confirmPasswordInput: $confirmPasswordInput)\n  }\n"): (typeof documents)["\n  mutation ConfirmPassword($confirmPasswordInput: ConfirmPasswordInput!) {\n    confirmPassword(confirmPasswordInput: $confirmPasswordInput)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation LoginUser($email: String!, $password: String!) {\n    loginUser(loginUserInput: { email: $email, password: $password }) {\n      accessToken\n      refreshToken\n    }\n  }\n"): (typeof documents)["\n  mutation LoginUser($email: String!, $password: String!) {\n    loginUser(loginUserInput: { email: $email, password: $password }) {\n      accessToken\n      refreshToken\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ConfirmRegistration(\n    $confirmRegistrationInput: ConfirmRegistartionInput!\n  ) {\n    confirmRegistration(confirmRegistartionInput: $confirmRegistrationInput)\n  }\n"): (typeof documents)["\n  mutation ConfirmRegistration(\n    $confirmRegistrationInput: ConfirmRegistartionInput!\n  ) {\n    confirmRegistration(confirmRegistartionInput: $confirmRegistrationInput)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ForgotPassword($forgotPasswordInput: ForgotPasswordInput!) {\n    forgotPassword(forgotPasswordInput: $forgotPasswordInput)\n  }\n"): (typeof documents)["\n  mutation ForgotPassword($forgotPasswordInput: ForgotPasswordInput!) {\n    forgotPassword(forgotPasswordInput: $forgotPasswordInput)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateQuestions($questions: [UpdateQuestionInput!]!) {\n    updateQuestions(questions: $questions) {\n      id\n      group {\n        groupName\n      }\n      groupId\n      questionId\n      questionName\n      isInSurvey\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateQuestions($questions: [UpdateQuestionInput!]!) {\n    updateQuestions(questions: $questions) {\n      id\n      group {\n        groupName\n      }\n      groupId\n      questionId\n      questionName\n      isInSurvey\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetAllCompanies{\n    getAllCompanies{\n      name\n      surname\n      company {\n        id\n        companyName\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetAllCompanies{\n    getAllCompanies{\n      name\n      surname\n      company {\n        id\n        companyName\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateTest($createTestInput: CreateTestInput!) {\n    createTest(createTestInput: $createTestInput) {\n      id\n      userId\n      answers {\n        answerId\n        value\n        duration\n      }\n      timeStart\n      timeEnd\n      timeSpent\n      createdAt\n    }\n  }\n"): (typeof documents)["\n  mutation CreateTest($createTestInput: CreateTestInput!) {\n    createTest(createTestInput: $createTestInput) {\n      id\n      userId\n      answers {\n        answerId\n        value\n        duration\n      }\n      timeStart\n      timeEnd\n      timeSpent\n      createdAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GET_AUTH {\n    getAuth\n  }\n"): (typeof documents)["\n  query GET_AUTH {\n    getAuth\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GET_QUESTIONS {\n    questions {\n      id\n      group {\n        groupName\n      }\n      groupId\n      questionId\n      questionName\n      isInSurvey\n    }\n  }\n"): (typeof documents)["\n  query GET_QUESTIONS {\n    questions {\n      id\n      group {\n        groupName\n      }\n      groupId\n      questionId\n      questionName\n      isInSurvey\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GET_RESULTS {\n    resultsDashboardCurrentUser {\n      totalTimeSpent\n      charts {\n        groupName\n        groupId\n\n        styles {\n          styleName\n          average\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GET_RESULTS {\n    resultsDashboardCurrentUser {\n      totalTimeSpent\n      charts {\n        groupName\n        groupId\n\n        styles {\n          styleName\n          average\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GET_SURVEY {\n    questionsSurvey {\n      id\n      group {\n        groupName\n      }\n      groupId\n      questionId\n      questionName\n      isInSurvey\n      answers {\n        answerId\n        answerName\n        id\n        values\n        style {\n          id\n        }\n      }\n      group {\n        groupId\n        groupName\n        groupDescription\n      }\n      questionId\n      questionName\n    }\n  }\n"): (typeof documents)["\n  query GET_SURVEY {\n    questionsSurvey {\n      id\n      group {\n        groupName\n      }\n      groupId\n      questionId\n      questionName\n      isInSurvey\n      answers {\n        answerId\n        answerName\n        id\n        values\n        style {\n          id\n        }\n      }\n      group {\n        groupId\n        groupName\n        groupDescription\n      }\n      questionId\n      questionName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query RESULTS_ADMIN_DASHBOARD {\n    resultsAdminDashboard {\n      totalUsers\n      finishedSurveys\n      percentageOfCompletedSurveys\n    }\n  }\n"): (typeof documents)["\n  query RESULTS_ADMIN_DASHBOARD {\n    resultsAdminDashboard {\n      totalUsers\n      finishedSurveys\n      percentageOfCompletedSurveys\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ADMIN_DASHBOARD_CHARTS($year: Float!) {\n    resultsAdminDashboardCharts(year: $year) {\n      groupId\n      styles {\n        id\n        styleName\n        groupId\n        avarage\n        count\n      }\n      mixed\n    }\n  }\n"): (typeof documents)["\n  query ADMIN_DASHBOARD_CHARTS($year: Float!) {\n    resultsAdminDashboardCharts(year: $year) {\n      groupId\n      styles {\n        id\n        styleName\n        groupId\n        avarage\n        count\n      }\n      mixed\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query RESULTS_MANAGER_DASHBOARD($departmentId: String!) {\n    resultsManagerDashboard(departmentId: $departmentId) {\n      totalUsers\n      finishedSurveys\n      percentageOfCompletedSurveys\n    }\n  }\n"): (typeof documents)["\n  query RESULTS_MANAGER_DASHBOARD($departmentId: String!) {\n    resultsManagerDashboard(departmentId: $departmentId) {\n      totalUsers\n      finishedSurveys\n      percentageOfCompletedSurveys\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MANAGER_DASHBOARD_CHARTS($year: Float!, $departmentId: String!) {\n    resultsManagerDashboardCharts(year: $year, departmentId: $departmentId) {\n      groupId\n      styles {\n        id\n        styleName\n        groupId\n        avarage\n        count\n      }\n      mixed\n    }\n  }\n"): (typeof documents)["\n  query MANAGER_DASHBOARD_CHARTS($year: Float!, $departmentId: String!) {\n    resultsManagerDashboardCharts(year: $year, departmentId: $departmentId) {\n      groupId\n      styles {\n        id\n        styleName\n        groupId\n        avarage\n        count\n      }\n      mixed\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GET_STYLES {\n    styles {\n      styleName\n    }\n  }\n"): (typeof documents)["\n  query GET_STYLES {\n    styles {\n      styleName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GET_USER {\n    me {\n      sub\n      name\n      surname\n      birthdate\n      email\n      roles\n      isSurveyDone\n      companyIdHash\n      departmentIdHash\n    }\n  }\n"): (typeof documents)["\n  query GET_USER {\n    me {\n      sub\n      name\n      surname\n      birthdate\n      email\n      roles\n      isSurveyDone\n      companyIdHash\n      departmentIdHash\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GET_DEPARTMENT_LIST($companyId: String!) {\n    companyDepartmentList(companyId: $companyId) {\n      departmentId\n      departmentName\n      managerName\n      companyName\n    }\n  }\n"): (typeof documents)["\n  query GET_DEPARTMENT_LIST($companyId: String!) {\n    companyDepartmentList(companyId: $companyId) {\n      departmentId\n      departmentName\n      managerName\n      companyName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GET_USER_LIST($departmentId: String!, $managerId: String) {\n    departmentUsersList(departmentId: $departmentId, managerId: $managerId) {\n      users {\n        sub\n        name\n        surname\n        email\n      }\n      departmentName\n    }\n  }\n"): (typeof documents)["\n  query GET_USER_LIST($departmentId: String!, $managerId: String) {\n    departmentUsersList(departmentId: $departmentId, managerId: $managerId) {\n      users {\n        sub\n        name\n        surname\n        email\n      }\n      departmentName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GET_USER_RESULTS($sub: String!) {\n    resultsDashboardClickedUser(sub: $sub) {\n      totalTimeSpent\n      charts {\n        groupName\n        groupId\n\n        styles {\n          styleName\n          average\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GET_USER_RESULTS($sub: String!) {\n    resultsDashboardClickedUser(sub: $sub) {\n      totalTimeSpent\n      charts {\n        groupName\n        groupId\n\n        styles {\n          styleName\n          average\n        }\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;