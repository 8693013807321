import { Question } from "gql/graphql";

export const getInitialValuesFromQuestions = (
  questions: Question[]
): { [key: string]: boolean } => {
  const initialValues = questions.reduce((acc, curr) => {
    acc[curr.id] = curr.isInSurvey as boolean;
    return acc;
  }, {} as { [key: string]: boolean });

  return initialValues;
};
