import { AdminRegisterForm } from "components/forms/AdminRegisterForm";

import { LoginLayout } from "layout/LoginLayout";
import { ReactElement } from "react";

export const AdminRegistration = (): ReactElement => {
  return (
    <LoginLayout heading="Welcome to KOSKO">
      <AdminRegisterForm />
    </LoginLayout>
  );
};
