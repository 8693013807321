import { ReactElement, useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { CREATE_TEST_INPUT, GET_SURVEY } from "graphql/queries";

import { CenteredContainer } from "components/CenteredContainer";
import { Intro } from "pages/survey/components/Intro";
import { Question } from "pages/survey/components/Question";
import ProgressBar from "components/ProgressBar";
import { FormStepper } from "pages/survey/components/FormStepper";

import { AnswerTestInput } from "gql/graphql";

export interface SurveyAnswer {
  answerId: string;
  answerName: string;
  id: string;
  values: number[];
  style: {
    id: string;
  };
}

interface SurveyQuestion {
  id: string;
  groupId: string;
  isInSurvey: boolean;
  questionId: string;
  questionName: string;
  group: { groupName: string };
  answers: SurveyAnswer[];
}

interface InitialValues {
  [key: string]: {
    answerId: string;
    duration: string;
    styleId: string;
    value: null | number;
  };
}

interface ValidationSchema {
  [key: number]: { [key: string]: Yup.AnyObjectSchema };
}

const prepareInitialValuesFromQuestions = (questions: SurveyQuestion[]) => {
  const initialValues: InitialValues = {};

  questions.forEach((question) => {
    question.answers.forEach((answer) => {
      initialValues[answer.id] = {
        answerId: answer.id,
        duration: "",
        styleId: "",
        value: null,
      };
    });
  });

  return initialValues;
};

const createValidationSchema = (questions: SurveyQuestion[]) => {
  const schema: ValidationSchema = {};

  for (let i = 0; i < questions.length; i++) {
    const schemaForStep: { [id: string]: Yup.AnyObjectSchema } = {};
    questions[i].answers.forEach((answer) => {
      schemaForStep[answer.id] = Yup.object({
        value: Yup.number().min(0),
      });
    });
    // @ts-ignore TO-DO FIX ME
    schema[i] = Yup.object().shape(schemaForStep);
  }

  return schema;
};

export const Survey = (): ReactElement => {
  const [questions, setQuestions] = useState<SurveyQuestion[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [validationSchema, setValidationSchema] = useState<ValidationSchema>();
  const [surveyTimeData, setSurveyTimeData] = useState<{
    timeStart: Date | null;
    timeEnd: Date | null;
  }>({
    timeStart: null,
    timeEnd: null,
  });
  const [initialValues, setInitialValues] = useState<InitialValues>({});

  const { loading, data, error } = useQuery(GET_SURVEY, {
    fetchPolicy: "network-only",
  });
  const [createTestInput] = useMutation(CREATE_TEST_INPUT);

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (error?.message === "User has already completed the survey.") {
      navigate("/dashboard");
    }
  }, [navigate, error]);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (data && data.questionsSurvey) {
      setQuestions(data.questionsSurvey as SurveyQuestion[]);
      setInitialValues(
        prepareInitialValuesFromQuestions(
          data.questionsSurvey as SurveyQuestion[]
        )
      );
      setValidationSchema(() =>
        createValidationSchema(data.questionsSurvey as SurveyQuestion[])
      );
    }
  }, [loading, data]);

  useEffect(() => {
    const surveyContainer = document.getElementById("survey-container");

    if (surveyContainer) {
      surveyContainer.scrollIntoView();
    }
  }, [currentStep]);

  const mappedQuestions = questions.map((q, i) => (
    <Question
      title={q.questionName}
      answers={q.answers}
      questionNumber={i + 1}
    />
  ));

  return (
    <CenteredContainer alignItems="baseline">
      <Box w="100%" id="survey-container">
        <Box w="100%">
          <Box p={10}>
            <ProgressBar
              currentStep={currentStep}
              totalSteps={questions.length}
            />
          </Box>
          {currentStep === 0 ? (
            <Intro
              setCurrentStep={setCurrentStep}
              //   TO-DO change, when we have estimated time available
              completionTime={questions.length}
              nQuestions={questions.length}
              onSurveyStarted={() =>
                setSurveyTimeData({ ...surveyTimeData, timeStart: new Date() })
              }
            />
          ) : (
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                createTestInput({
                  variables: {
                    createTestInput: {
                      answers: Object.values(values) as AnswerTestInput[],
                      timeStart: surveyTimeData.timeStart,
                      timeEnd: new Date(),
                    },
                  },
                })
                  .then(() => {
                    toast.success(t("Form submitted!"), {
                      duration: 4000,
                    });

                    navigate("/dashboard");
                  })
                  .catch((err) =>
                    toast.error(t(err.message), { duration: 4000 })
                  );
              }}
              validationSchema={validationSchema![currentStep - 1]}
            >
              <FormStepper
                questionNumber={currentStep}
                nQuestions={questions.length}
                setCurrentStep={(step) => setCurrentStep(step)}
              >
                {mappedQuestions[currentStep - 1]}
              </FormStepper>
            </Formik>
          )}
        </Box>
      </Box>
    </CenteredContainer>
  );
};
