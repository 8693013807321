import { Flex, Text, Stack, StackDirection } from "@chakra-ui/react";
import { Circular } from "./Circular";
import { GraphData } from "./graphTypes";
import { useTranslation } from "react-i18next";
import { ReactElement } from "react";

export const PerspectiveGraph = ({ data }: GraphData): ReactElement => {
  const { t } = useTranslation();
  const showNoDataMessage = !data || data.length === 0;

  let direction: StackDirection | undefined = undefined;
  let height;
  if (data?.length === 4) {
    direction = "column";
    height = "204px";
  } else {
    direction = "row";
    height = "160px";
  }

  return !showNoDataMessage ? (
    <>
      <Stack
        spacing={5}
        direction={direction}
        wrap="wrap"
        justifyContent="space-between"
        height={height}
      >
        {data?.map((e) => (
          <Circular
            color={e.color || "#34B53A"}
            hasLegend
            name={e.name.toLowerCase()}
            value={e.value}
            key={e.name}
            count={e.count}
            total={e.total}
          />
        ))}
      </Stack>
      <Text fontSize="0.75rem" color="gray.500" w="100%">
        {t(
          "A psychological perspective is a specific way of observing and understanding human behavior."
        )}
      </Text>
    </>
  ) : (
    <Flex h="100%" alignItems="center">
      <p>{t("Not enough data to display the chart")}</p>
    </Flex>
  );
};
