import { useMutation } from "@apollo/client";
import { REGISTER_ADMIN } from "graphql/queries";

export const useRegisterAdmin = () => {
  const [registerAdmin, { data, error, loading }] = useMutation(REGISTER_ADMIN);

  const handleRegisterAdmin = (formData: {
    email: string;
    password: string;
    name: string;
    surname: string;
    birthdate: string;
    company: string;
  }) => {
    const { email, password, name, surname, birthdate, company } = formData;

    return registerAdmin({
      variables: {
        email,
        password,
        name,
        surname,
        birthdate,
        companyName: company,
      },
    });
  };

  return {
    handleRegisterAdmin,
    data,
    error,
    loading,
  };
};
