import { graphql } from "../gql";

// npx graphql-codegen to updates types

// MUTATIONS

export const REGISTER_USER = graphql(`
  mutation RegisterUser(
    $email: String!
    $name: String!
    $surname: String!
    $password: String!
    $birthdate: String!
  ) {
    registerUser(
      registerUserInput: {
        email: $email
        name: $name
        surname: $surname
        password: $password
        birthdate: $birthdate
      }
    )
  }
`);

export const REGISTER_USER_WITH_DEPARTMENT = graphql(`
  mutation RegisterUserWithDepartmentId(
    $email: String!
    $name: String!
    $surname: String!
    $password: String!
    $birthdate: String!
    $companyId: String!
    $departmentId: String!
  ) {
    registerUserWithDepartmentId(
      regWithDepartmentInput: {
        email: $email
        name: $name
        surname: $surname
        password: $password
        birthdate: $birthdate
        companyId: $companyId
        departmentId: $departmentId
      }
    )
  }
`);

export const REGISTER_MANAGER = graphql(`
  mutation CreateDepartmentWithManagerUser(
    $email: String!
    $name: String!
    $surname: String!
    $password: String!
    $birthdate: String!
    $companyId: String!
    $departmentName: String!
  ) {
    createDepartmentWithManagerUser(
      regWithDepartmentInput: {
        email: $email
        name: $name
        surname: $surname
        password: $password
        birthdate: $birthdate
        companyId: $companyId
        departmentName: $departmentName
      }
    ) {
      departmentName
      id
    }
  }
`);

export const REGISTER_ADMIN = graphql(`
  mutation CreateCompanyWithAdminUser(
    $email: String!
    $name: String!
    $surname: String!
    $password: String!
    $birthdate: String!
    $companyName: String!
  ) {
    createCompanyWithAdminUser(
      input: {
        email: $email
        name: $name
        surname: $surname
        password: $password
        birthdate: $birthdate
        companyName: $companyName
      }
    ) {
      companyName
      id
    }
  }
`);

export const CONFIRM_PASSWORD = graphql(`
  mutation ConfirmPassword($confirmPasswordInput: ConfirmPasswordInput!) {
    confirmPassword(confirmPasswordInput: $confirmPasswordInput)
  }
`);

export const LOGIN_USER = graphql(`
  mutation LoginUser($email: String!, $password: String!) {
    loginUser(loginUserInput: { email: $email, password: $password }) {
      accessToken
      refreshToken
    }
  }
`);

export const CONFIRM_REGISTRATION = graphql(`
  mutation ConfirmRegistration(
    $confirmRegistrationInput: ConfirmRegistartionInput!
  ) {
    confirmRegistration(confirmRegistartionInput: $confirmRegistrationInput)
  }
`);

export const FORGOT_PASSWORD = graphql(`
  mutation ForgotPassword($forgotPasswordInput: ForgotPasswordInput!) {
    forgotPassword(forgotPasswordInput: $forgotPasswordInput)
  }
`);

export const UPDATE_QUESTIONS = graphql(`
  mutation UpdateQuestions($questions: [UpdateQuestionInput!]!) {
    updateQuestions(questions: $questions) {
      id
      group {
        groupName
      }
      groupId
      questionId
      questionName
      isInSurvey
    }
  }
`);

export const GET_ALL_COMPANIES = graphql(`
  query GetAllCompanies{
    getAllCompanies{
      name
      surname
      company {
        id
        companyName
      }
    }
  }
`)

export const CREATE_TEST_INPUT = graphql(`
  mutation CreateTest($createTestInput: CreateTestInput!) {
    createTest(createTestInput: $createTestInput) {
      id
      userId
      answers {
        answerId
        value
        duration
      }
      timeStart
      timeEnd
      timeSpent
      createdAt
    }
  }
`);

// QUERIES

export const GET_AUTH = graphql(`
  query GET_AUTH {
    getAuth
  }
`);

export const GET_QUESTIONS = graphql(`
  query GET_QUESTIONS {
    questions {
      id
      group {
        groupName
      }
      groupId
      questionId
      questionName
      isInSurvey
    }
  }
`);

export const GET_RESULTS = graphql(`
  query GET_RESULTS {
    resultsDashboardCurrentUser {
      totalTimeSpent
      charts {
        groupName
        groupId

        styles {
          styleName
          average
        }
      }
    }
  }
`);

export const GET_SURVEY = graphql(`
  query GET_SURVEY {
    questionsSurvey {
      id
      group {
        groupName
      }
      groupId
      questionId
      questionName
      isInSurvey
      answers {
        answerId
        answerName
        id
        values
        style {
          id
        }
      }
      group {
        groupId
        groupName
        groupDescription
      }
      questionId
      questionName
    }
  }
`);

export const RESULTS_ADMIN_DASHBOARD = graphql(`
  query RESULTS_ADMIN_DASHBOARD {
    resultsAdminDashboard {
      totalUsers
      finishedSurveys
      percentageOfCompletedSurveys
    }
  }
`);

export const ADMIN_DASHBOARD_CHARTS = graphql(`
  query ADMIN_DASHBOARD_CHARTS($year: Float!) {
    resultsAdminDashboardCharts(year: $year) {
      groupId
      styles {
        id
        styleName
        groupId
        avarage
        count
      }
      mixed
    }
  }
`);

export const RESULTS_MANAGER_DASHBOARD = graphql(`
  query RESULTS_MANAGER_DASHBOARD($departmentId: String!) {
    resultsManagerDashboard(departmentId: $departmentId) {
      totalUsers
      finishedSurveys
      percentageOfCompletedSurveys
    }
  }
`);

export const MANAGER_DASHBOARD_CHARTS = graphql(`
  query MANAGER_DASHBOARD_CHARTS($year: Float!, $departmentId: String!) {
    resultsManagerDashboardCharts(year: $year, departmentId: $departmentId) {
      groupId
      styles {
        id
        styleName
        groupId
        avarage
        count
      }
      mixed
    }
  }
`);

export const GET_STYLES = graphql(`
  query GET_STYLES {
    styles {
      styleName
    }
  }
`);

export const GET_USER = graphql(`
  query GET_USER {
    me {
      sub
      name
      surname
      birthdate
      email
      roles
      isSurveyDone
      companyIdHash
      departmentIdHash
    }
  }
`);

export const GET_DEPARTMENT_LIST = graphql(`
  query GET_DEPARTMENT_LIST($companyId: String!) {
    companyDepartmentList(companyId: $companyId) {
      departmentId
      departmentName
      managerName
      companyName
    }
  }
`);

export const GET_USER_LIST = graphql(`
  query GET_USER_LIST($departmentId: String!, $managerId: String) {
    departmentUsersList(departmentId: $departmentId, managerId: $managerId) {
      users {
        sub
        name
        surname
        email
      }
      departmentName
    }
  }
`);

export const GET_USER_RESULTS = graphql(`
  query GET_USER_RESULTS($sub: String!) {
    resultsDashboardClickedUser(sub: $sub) {
      totalTimeSpent
      charts {
        groupName
        groupId

        styles {
          styleName
          average
        }
      }
    }
  }
`);
