import {
  HStack,
  Heading,
  IconButton,
  Flex,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { BsThreeDots } from "react-icons/bs";

type Props = {
  title: string;
  children: ReactElement;
  fontSize?: string;
  iconColor?: string;
  width?: string;
  height?: string;
};

export const TestCard = ({
  title,
  fontSize = "md",
  iconColor = "gray.500",
  children,
  width,
  height,
}: Props): ReactElement => {
  const variantWidth = useBreakpointValue(
    {
      base: "400px",
      xl: "",
    },
    {
      fallback: "",
    }
  );

  const { t } = useTranslation();

  return (
    <Flex
      backgroundColor="white"
      borderRadius="md"
      p={5}
      pb={4}
      width={width ?? variantWidth}
      height={height ?? "auto"}
      flexDirection="column"
      alignSelf="start"
      boxSizing="content-box"
    >
      <Flex
        h="100%"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <HStack justify="space-between" w="100%">
          <Heading fontSize={fontSize} fontFamily="DM Sans" fontWeight={400}>
            {t(title)}
          </Heading>
          <IconButton
            variant="ghost"
            color={iconColor}
            aria-label="See menu"
            icon={<BsThreeDots />}
          />
        </HStack>
        {children}
      </Flex>
    </Flex>
  );
};
