import { Button, HStack, Heading, Image, Text, VStack } from "@chakra-ui/react";
import { ArrowForwardIcon, TimeIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";

import head from "assets/images/survey/head.png";
import { ReactElement } from "react";

type Props = {
  nQuestions: number;
  completionTime: number;
  setCurrentStep: Function;
  onSurveyStarted: () => void;
};

export const Intro = ({
  nQuestions = 0,
  completionTime = 0,
  setCurrentStep,
  onSurveyStarted,
}: Props): ReactElement => {
  const { t } = useTranslation();

  return (
    <VStack justify="space-evenly" h="100%" minH="38rem">
      <Image maxW={290} src={head} />
      <VStack spacing={5}>
        <Heading fontFamily="Nunito" fontWeight={900} fontSize="3xl">
          {t("Perception")}
        </Heading>
        <Text fontFamily="Mulish" maxW={450} textAlign="center">
          {t(
            "Know more about your sensory channels, primary interest and perspective"
          )}
        </Text>
        <HStack spacing={3}>
          <HStack spacing={1}>
            <ChevronRightIcon boxSize="1.3em" color="gray" />
            <Text fontFamily="Mulish" color="gray" fontWeight={700}>
              {nQuestions} {t("questions")}
            </Text>
          </HStack>
          <HStack spacing={1}>
            <TimeIcon boxSize=".7em" color="gray" />
            <Text fontFamily="Mulish" color="gray" fontWeight={700}>
              {completionTime} {t("mins to complete")}
            </Text>
          </HStack>
        </HStack>
      </VStack>
      <Button
        onClick={() => {
          setCurrentStep(1);
          onSurveyStarted();
        }}
        fontFamily="Lato"
        w="2xs"
        rightIcon={<ArrowForwardIcon />}
      >
        {t("Start now")}
      </Button>
    </VStack>
  );
};
