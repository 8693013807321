import { Form, Formik } from "formik";
import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { LoginInput } from "pages/login/components/LoginInput";
import { forgotPasswordValidation } from "components/forms/validation/forgotPasswordValidation";
import { useMutation } from "@apollo/client";
import { CONFIRM_PASSWORD } from "graphql/queries";
import { ReactElement } from "react";

interface ChangePasswordModalProps {
  isModalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  email: string;
  onEmailSet: (email: string) => void;
}

interface ChangePasswordFormValues {
  code: string;
  password: string;
}

export const ChangePasswordModal = ({
  isModalOpen,
  setModalOpen,
  email,
  onEmailSet,
}: ChangePasswordModalProps): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [confirmPassword] = useMutation(CONFIRM_PASSWORD);

  const handleSubmit = (val: ChangePasswordFormValues) => {
    confirmPassword({
      variables: {
        confirmPasswordInput: {
          email,
          confirmationCode: val.code,
          newPassword: val.password,
        },
      },
    })
      .then(() => {
        toast.success(t("Password changer confirmed. Now you can login."), {
          duration: 4000,
        });
        navigate("/login");
        setModalOpen(false);
      })
      .catch((e) => {
        toast.error(t(e.message), { duration: 4000 });
      });
  };

  const initialValues = {
    code: "",
    password: "",
    repeatPassword: "",
    email: email,
  };

  return (
    <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
      <ModalOverlay />
      <ModalContent p={5}>
        <ModalHeader>{t("Password Reset")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            {t(
              "We have send a password reset code to your email. Enter it below to reset password."
            )}
          </Box>
        </ModalBody>
        <Formik
          onSubmit={(val) => {
            handleSubmit(val);
          }}
          initialValues={initialValues}
          validationSchema={forgotPasswordValidation}
        >
          <Form style={{ padding: "1.5rem" }}>
            <Input
              fontFamily="Lato"
              fontSize="xs"
              placeholder={t("Your Email")!}
              type="email"
              name="email"
              mb={3}
              value={email}
              onChange={(e) => onEmailSet(e.target.value)}
            />

            <LoginInput
              name="code"
              placeholder="Code from email"
              type="text"
              autocomplete="new-password"
            />
            <LoginInput
              name="password"
              placeholder="New Password"
              type="password"
              autocomplete="new-password"
            />
            <LoginInput
              name="confirmationPassword"
              placeholder="Repeat password"
              type="password"
              autocomplete="new-password"
            />
            <Button mr={3} type="submit">
              {t("Change password")}
            </Button>
          </Form>
        </Formik>
      </ModalContent>
    </Modal>
  );
};
