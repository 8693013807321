import { HStack, Icon, Text } from "@chakra-ui/react";
import { ReactElement, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "context/Auth";

type Props = {
  icon: any;
  text: string;
  path: string;
  highlightForSuperAdmin?: boolean;
};

export const NavButton = ({
  icon,
  text,
  path,
  highlightForSuperAdmin,
}: Props): ReactElement => {
  const { pathname } = useLocation();
  const { isSuperAdmin } = useContext(AuthContext);

  const isSelected = isSuperAdmin
    ? highlightForSuperAdmin
    : pathname.includes(path);
  return (
    <Link style={{ width: "100%" }} to={path}>
      <HStack
        width="100%"
        p={4}
        pl={7}
        borderLeft={isSelected ? "3px solid black" : undefined}
        background={isSelected ? "#e0d8f7" : "white"}
        cursor="pointer"
        spacing={5}
        _hover={{ background: "#e0d8f7" }}
      >
        <Icon as={icon} color={isSelected ? "black" : "#4d4d4d"} />
        <Text color={isSelected ? "black" : "#4d4d4d"}>{text}</Text>
      </HStack>
    </Link>
  );
};
