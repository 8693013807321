import { Form, Formik } from "formik";
import { Button, Link, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ReactElement, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { LoginInput } from "pages/login/components/LoginInput";
import { AuthContext } from "context/Auth";
import { styles } from "components/forms/styles/formStyles";

const initialValues = { email: "", password: "" };

export const LoginForm = (): ReactElement => {
  const { loginUser, isSubmitting } = useContext(AuthContext);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { fontFamily, fontSize, fontWeight, color } = styles;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(val) => {
        loginUser(val.email, val.password);
      }}
    >
      <Form>
        <LoginInput
          name="email"
          placeholder={t("Your name or Email") || ""}
          id="login-email"
          autocomplete="username"
        />
        <LoginInput
          type="password"
          name="password"
          placeholder={t("Password") || ""}
          autocomplete="current-password"
        />

        <Button
          fontSize={fontSize}
          width="100%"
          mt={6}
          mb={3}
          type="submit"
          isLoading={isSubmitting}
        >
          {t("Login")}
        </Button>

        <Text fontSize={fontSize}>
          {t("Forgot password?")}
          <Link
            fontFamily={fontFamily}
            fontSize={fontSize}
            fontWeight={fontWeight}
            color={color}
            ml={1}
            onClick={() => navigate("/forgot-password")}
          >
            {t("Reset here")}
          </Link>
        </Text>
      </Form>
    </Formik>
  );
};
