import { Box, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import { hexToRGB } from "../../../../../helpers/helpers";
import { GraphData } from "./graphTypes";
import { useTranslation } from "react-i18next";
import { ReactElement } from "react";

export const Bar = ({ data }: GraphData): ReactElement => {
  const { t } = useTranslation();
  const showNoDataMessage = !data || data.length === 0;

  return (
    <>
      <VStack minW={300} w="100%" spacing={3}>
        {showNoDataMessage ? (
          <Flex h="100%" alignItems="center">
            <p>{t("Not enough data to display the chart")}</p>
          </Flex>
        ) : (
          <>
            {data.map((e) => (
              <Box width="100%" fontSize="10px" key={e.name} mt="0.7rem">
                <HStack
                  fontFamily="DM Sans"
                  fontSize="0.75rem"
                  justify="space-between"
                  w="100%"
                  mb={1}
                >
                  <Text textTransform="capitalize">
                    {t(e.name.toLowerCase())}
                  </Text>
                  <Text color={e.color}>
                    {e.total
                      ? `${e.count}/${e.total} persons`
                      : `${e.value.toFixed()}%`}
                  </Text>
                </HStack>
                <Box
                  borderRadius="md"
                  w="100%"
                  h={1.5}
                  backgroundColor={e.color ? hexToRGB(e.color, "0.2") : "none"}
                >
                  {/* filling here */}
                  <Box
                    borderRadius="md"
                    w={`${e.value}%`}
                    h="100%"
                    backgroundColor={e.color}
                  />
                </Box>
              </Box>
            ))}
          </>
        )}
      </VStack>
    </>
  );
};
