import { DashboardLayout } from "../../layout/DashboardLayout";
import { ReactElement, useEffect, useState, useContext } from "react";
import {
  HStack,
  VStack,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  Text,
  Image,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/Auth";
import { TestCard } from "pages/dashboard/components/main/TestCard";
import { Radial } from "pages/dashboard/components/main/graphs/Radial";
import { Bar } from "pages/dashboard/components/main/graphs/Bar";
import { PerspectiveGraph } from "pages/dashboard/components/main/graphs/PerspectiveGraph";
import { PieChartGraph } from "pages/dashboard/components/main/graphs/PieChartGraph";
import { Section } from "./components/main/Section";
import { adminDashboardColors as colors } from "./components/main/graphs/graphTypes";
import { LoadingPage } from "pages/loading/LoadingPage";
import noDataPicture from "../../assets/images/dashboard/no_data.jpg";

import {
  ADMIN_DASHBOARD_CHARTS,
  RESULTS_ADMIN_DASHBOARD,
  MANAGER_DASHBOARD_CHARTS,
  RESULTS_MANAGER_DASHBOARD,
  GET_DEPARTMENT_LIST,
} from "graphql/queries";
import { Get_Department_ListQuery } from "gql/graphql";

import { ChangeCard } from "pages/dashboard/components/main/ChangeCard";

type UserData = {
  resultsManagerDashboard?: {
    totalUsers?: number | null;
    finishedSurveys?: number | null;
    percentageOfCompletedSurveys?: number | null;
  };
  resultsAdminDashboard?: {
    totalUsers?: number | null;
    finishedSurveys?: number | null;
    percentageOfCompletedSurveys?: number | null;
  };
};

type Styles = {
  avarage: number;
  count: number;
  groupId: string;
  id: string;
  styleName: string;
}[];

type DashboardCharts = {
  __typename: string;
  groupId: string;
  mixed: number;
  styles: Styles;
}[];

type ChartsData = {
  resultsAdminDashboardCharts?: DashboardCharts;
  resultsManagerDashboardCharts?: DashboardCharts;
};

export const AdminDashboard = (): ReactElement => {
  const [usersData, setUsersData] = useState<UserData>();
  const [chartsData, setChartsData] = useState<ChartsData>();
  const [departmentsList, setDepartmentsList] =
    useState<Get_Department_ListQuery>();
  const [selectedDepartment, setSelectedDepartment] = useState<
    string | undefined
  >();

  const { isAdmin } = useContext(AuthContext);
  let companyId;
  const storedValue = localStorage.getItem("companyId");
  if (storedValue) {
    companyId = JSON.parse(storedValue);
  }

  let departmentId: string | undefined;
  if (selectedDepartment) {
    departmentId =
      (departmentsList?.companyDepartmentList?.find(
        (department) => department.departmentName === selectedDepartment
      )?.departmentId as string) || undefined;
  } else if (localStorage.departmentId) {
    const storedValue = localStorage.getItem("departmentId");
    departmentId = storedValue
      ? (JSON.parse(storedValue) as string)
      : undefined;
  }

  const queryType =
    selectedDepartment || departmentId
      ? RESULTS_MANAGER_DASHBOARD
      : RESULTS_ADMIN_DASHBOARD;

  const {
    loading,
    data,
    refetch: refetchData,
  } = useQuery<
    typeof RESULTS_MANAGER_DASHBOARD | typeof RESULTS_ADMIN_DASHBOARD
  >(queryType, {
    fetchPolicy: "network-only",
    variables: {
      departmentId: departmentId || "",
    },
  });

  const { loading: loadingChartsData, data: adminChartsData } = useQuery(
    selectedDepartment || departmentId
      ? MANAGER_DASHBOARD_CHARTS
      : ADMIN_DASHBOARD_CHARTS,
    {
      fetchPolicy: "network-only",
      variables: {
        year: 2024,
        departmentId: departmentId!,
      },
    }
  );

  const { loading: loadingDepartments, data: dataDepartments } = useQuery(
    GET_DEPARTMENT_LIST,
    {
      fetchPolicy: "network-only",
      variables: {
        companyId: companyId,
      },
      skip: !isAdmin,
    }
  );

  useEffect(() => {
    if (data) {
      setUsersData(data as UserData);
    }
  }, [data, loading]);

  useEffect(() => {
    if (selectedDepartment || departmentId) {
      refetchData().then((result) => {
        setUsersData(result.data as UserData);
      });
    }
  }, [selectedDepartment, refetchData, departmentId]);

  useEffect(() => {
    if (adminChartsData) {
      setChartsData(adminChartsData as ChartsData);
    }
  }, [adminChartsData, loadingChartsData]);

  useEffect(() => {
    if (dataDepartments) {
      setDepartmentsList(dataDepartments);
    }
  }, [dataDepartments, loadingDepartments]);

  const findAndMapGraph = (name: string) => {
    const charts =
      selectedDepartment || departmentId
        ? chartsData?.resultsManagerDashboardCharts
        : chartsData?.resultsAdminDashboardCharts;
    let chart = charts?.find((e) => e.groupId === name);

    let totalUsers =
      selectedDepartment || departmentId
        ? usersData?.resultsManagerDashboard?.totalUsers
        : usersData?.resultsAdminDashboard?.totalUsers;

    if (!chart) return undefined;

    const data = chart.styles.map((style, index) => ({
      name:
        style.styleName.charAt(0).toUpperCase() +
        style.styleName.slice(1).toLowerCase(),
      value: totalUsers ? (style.count * 100) / totalUsers : 0,
      color: findColor(style.groupId, index),
      count: style.count,
      total: totalUsers || undefined,
    }));
    data.push({
      name: "Mista",
      value: totalUsers ? (chart.mixed * 100) / totalUsers : 0,
      color: findColor(chart.groupId),
      count: chart.mixed,
      total: totalUsers || undefined,
    });
    return data;
  };

  const findColor = (id: string, index?: number) => {
    const groupColors = colors.find((color) => color.id === id)?.colors;

    if (typeof index !== "undefined") {
      return groupColors?.[index];
    } else {
      return groupColors?.[groupColors.length - 1];
    }
  };

  const departmentsOptions = [
    <MenuItem
      key="company"
      value="Company"
      onClick={() => handleSelectChange("" as string)}
      _hover={{ bg: "#f5f2fc" }}
    >
      Company
    </MenuItem>,
    ...(departmentsList?.companyDepartmentList
      .filter((department) => department.departmentName !== selectedDepartment)
      .map((department) => (
        <MenuItem
          key={department.departmentId}
          value={department.departmentName ?? ""}
          _hover={{ bg: "#f5f2fc" }}
          onClick={() =>
            handleSelectChange(department.departmentName as string)
          }
        >
          {department.departmentName}
        </MenuItem>
      )) ?? []),
  ];

  const handleSelectChange = (departmentName: string) => {
    setSelectedDepartment(
      departmentsList?.companyDepartmentList.find(
        (department) => department.departmentName === departmentName
      )?.departmentName as string
    );
  };

  const isStaticticShown =
    (chartsData &&
      chartsData.resultsAdminDashboardCharts &&
      chartsData.resultsAdminDashboardCharts.length > 0) ||
    (chartsData?.resultsManagerDashboardCharts &&
      chartsData.resultsManagerDashboardCharts.length > 0);

  return (
    <DashboardLayout>
      <VStack align="flex-start" maxW="1250px" w="100%" spacing={5}>
        {!data && <LoadingPage />}
        {data && chartsData ? (
          <>
            <HStack
              spacing={5}
              alignItems="start"
              justify="space-between"
              w="100%"
            >
              <Flex direction="column" gap="50px">
                {isAdmin && (
                  <Heading color="#262626" fontSize="large" paddingTop="7px">
                    Statistic
                  </Heading>
                )}
                {isStaticticShown && (
                  <ChangeCard
                    color="#987de6"
                    title="Surveys"
                    value={
                      isAdmin && !selectedDepartment && !departmentId
                        ? (usersData?.resultsAdminDashboard
                            ?.totalUsers as number)
                        : (usersData?.resultsManagerDashboard
                            ?.totalUsers as number)
                    }
                    changeValue={
                      isAdmin && !selectedDepartment && !departmentId
                        ? Math.floor(
                            usersData?.resultsAdminDashboard
                              ?.percentageOfCompletedSurveys as number
                          )
                        : Math.floor(
                            usersData?.resultsManagerDashboard
                              ?.percentageOfCompletedSurveys as number
                          )
                    }
                    tooltipLabel="Percentage of surveys completed"
                  />
                )}
              </Flex>
              {isAdmin && (
                <Menu>
                  {({ isOpen }) => (
                    <>
                      <MenuButton
                        as={Button}
                        w={125}
                        rightIcon={
                          isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />
                        }
                        borderRadius="md"
                        border="1px solid #e6e6e6"
                        fontWeight={400}
                        bg="#fff"
                        color="#4d4d4d"
                        _hover={{ bg: "#fff" }}
                      >
                        {selectedDepartment || "Company"}
                      </MenuButton>
                      <MenuList minWidth={125}>{departmentsOptions}</MenuList>
                    </>
                  )}
                </Menu>
              )}
            </HStack>
            {isStaticticShown ? (
              <Section title="Test results">
                <>
                  {/* @ts-ignore */}
                  <Flex gap={9} flexWrap="wrap" w="100%">
                    <TestCard
                      title="Sensory channel"
                      width="256px"
                      height="352px"
                    >
                      <Radial data={findAndMapGraph("G01")} />
                    </TestCard>

                    <TestCard title="Reference" width="400px" height="352px">
                      <Bar data={findAndMapGraph("G02")} />
                    </TestCard>

                    <TestCard title="Perspective" width="400px" height="352px">
                      <PerspectiveGraph data={findAndMapGraph("G03")} />
                    </TestCard>
                  </Flex>
                  <Flex gap={9}>
                    <TestCard title="Orientamento" width="256px" height="350px">
                      <PieChartGraph data={findAndMapGraph("G05")} />
                    </TestCard>
                    <TestCard title="Referenza" width="256px" height="350px">
                      <PieChartGraph data={findAndMapGraph("G06")} />
                    </TestCard>
                  </Flex>
                </>
              </Section>
            ) : (
              <Flex
                direction="column"
                maxW={832}
                w="100%"
                h={558}
                justifyContent="space-between"
                alignItems="center"
                alignSelf="center"
                bgColor="#fff"
                borderRadius="16px"
                padding="48px 0"
                mt={30}
              >
                <Heading color="#1a1f2d" fontSize="xl">
                  No Data Available
                </Heading>
                <Text align="center" px={40} fontSize="s">
                  There's nothing here yet. Please visit the designated page to
                  share the link for those who need to complete the test.
                </Text>
                <Image src={noDataPicture} w={306} h="auto" />
                <Button
                  bg="#eae5fa"
                  color="#262626"
                  fontWeight={400}
                  w={120}
                  h={42}
                  _hover={{ bg: "#c1b1f0" }}
                >
                  <Link to="/users">Go to page</Link>
                </Button>
              </Flex>
            )}
          </>
        ) : null}
      </VStack>
    </DashboardLayout>
  );
};
