import { ReactElement } from "react";

import { Box, Flex, FormControl, Heading, Image, Text } from "@chakra-ui/react";

import { useTranslation } from "react-i18next";

import people from "assets/images/login/people.png";
import circles from "assets/images/login/circles.png";

import { CenteredContainer } from "components/CenteredContainer";

export const LoginLayout = ({
  children,
  heading,
}: {
  children: ReactElement;
  heading: string;
}): ReactElement => {
  const { t } = useTranslation();

  return (
    <CenteredContainer>
      <Flex justifyContent="space-between" gap={2} w="100vw">
        <Box
          w="50%"
          bg="brand.500"
          borderRadius="xl"
          position="relative"
          overflow="hidden"
          minH="45.75rem"
        >
          <Image position="absolute" bottom={-2} left={-2} src={circles} />
          <Box
            m="5% 5%"
            height="90%"
            zIndex={100}
            backdropFilter="auto"
            backdropBlur="8px"
            border=".8px solid white"
            borderRadius="xl"
            position="relative"
          >
            <Heading size="xl" color="white" p={5}>
              Knowing Ourselves Start Knowing Others
            </Heading>
            <Image position="absolute" bottom={0} right={-6} src={people} />
          </Box>
        </Box>
        <Flex
          flexDirection="column"
          justify="space-between"
          align="center"
          maxW={330}
          p={5}
          m="auto"
          mt={5}
          mb={0}
          gap={6}
        >
          <Box>
            <Heading
              color="brand.500"
              fontFamily="Roboto"
              mt={5}
              mb="3rem"
              textAlign="center"
            >
              KOSKO
            </Heading>

            <Heading fontFamily="Lato" size="md" mb={3} textAlign="center">
              {t(heading)}
            </Heading>

            <FormControl isRequired>{children}</FormControl>
          </Box>

          <Text fontFamily="Lato" fontSize="xs" color="gray">
            © 2024 KOSKO. {t("All Rights Reserved")}
          </Text>
        </Flex>
      </Flex>
    </CenteredContainer>
  );
};
