import { Form, Formik } from "formik";
import { Button, Link, Text } from "@chakra-ui/react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";

import { LoginInput } from "pages/login/components/LoginInput";
import { ConfirmRegistrationModal } from "components/forms/modals/ConfirmRegistrationModal";
import { registerAdminValidation } from "components/forms/validation/registerAdminValidation";
import { useRegisterAdmin } from "hooks/useRegisterAdmin";
import { adminRegistrationFromInputs } from "components/forms/fields/adminRegisterFields";

interface AdminRegisterFormValues {
  name: string;
  surname: string;
  birthdate: string;
  email: string;
  password: string;
  company: string;
}

const initialValues = {
  name: "",
  surname: "",
  birthdate: "",
  email: "",
  password: "",
  confirmationPassword: "",
  company: "",
};

export const AdminRegisterForm = (): ReactElement => {
  const { t } = useTranslation();
  const { handleRegisterAdmin } = useRegisterAdmin();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (
    values: AdminRegisterFormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const { email } = values;
    setSubmitting(true);
    setNewEmail(email);

    handleRegisterAdmin(values)
      .then(() => {
        toast.success(t("Admin created, please verify your mail!"), {
          duration: 4000,
        });
        setSubmitting(false);
        setIsModalOpen(true);
      })
      .catch((e) => {
        toast.error(t(e.message), { duration: 4000 });
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values, setSubmitting);
      }}
      validationSchema={registerAdminValidation}
    >
      {({ isSubmitting }) => (
        <Form>
          <ConfirmRegistrationModal
            isModalOpen={isModalOpen}
            setModalOpen={setIsModalOpen}
            email={newEmail}
          />
          {adminRegistrationFromInputs.map((element) => (
            <LoginInput
              name={element.name}
              placeholder={element.placeHolder}
              type={element.type || "text"}
              key={element.name}
            />
          ))}
          <Button
            fontSize="sm"
            width="100%"
            mt={4}
            type="submit"
            mb={3}
            isLoading={isSubmitting}
          >
            {t("Sign up")}
          </Button>
          <Text fontSize="sm">
            {t("Already have the verification code?")}
            <Button
              fontFamily="Lato"
              fontSize="sm"
              fontWeight="bold"
              color="brand.500"
              onClick={() => setIsModalOpen(true)}
              variant="text"
              mb="0.25rem"
              ml={1}
            >
              {t("Enter the code")}
            </Button>
          </Text>
          <Link
            fontFamily="Lato"
            fontSize="sm"
            fontWeight="bold"
            color="brand.500"
            onClick={() => navigate("/login")}
          >
            {t("Login here")}
          </Link>
        </Form>
      )}
    </Formik>
  );
};
