import { Button, Flex } from "@chakra-ui/react";
import { ReactElement, useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";

import { DashboardLayout } from "layout/DashboardLayout";

import { AuthContext } from "context/Auth";

export const Profile = (): ReactElement => {
  const { logoutUser, isAdmin } = useContext(AuthContext);

  const { t } = useTranslation();

  return (
    <DashboardLayout>
      <Flex justifyContent="flex-end" gap="2rem">
        {isAdmin ? (
          <Button>
            <Link to="/survey-manager">{t("Go to Survey Manager")}</Link>
          </Button>
        ) : null}
        <Button
          onClick={() => {
            logoutUser();
            toast(t("Bye bye") + " 👋🏻");
          }}
        >
          {t("Log out")}
        </Button>
      </Flex>
    </DashboardLayout>
  );
};
