import * as Yup from "yup";

export const registerValidation = (isManager: boolean) => {
  return Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(40, "Too Long!")
      .required("Required"),
    surname: Yup.string()
      .min(2, "Too Short!")
      .max(40, "Too Long!")
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string()
      .required("Required")
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      ),
    confirmationPassword: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
    birthdate: Yup.date()
      .max(new Date(), "Birthdate must be in the past")
      .required("Required"),
    department: isManager
      ? Yup.string()
          .required("Required")
          .matches(
            /^[A-Za-z0-9]+$/,
            "Department can only contain letters and numbers"
          )
      : Yup.string().strip(),
  });
};
