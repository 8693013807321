import { ReactElement, useContext } from "react";
import { Navigate } from "react-router-dom";

import { AuthContext } from "context/Auth";
import { LoadingPage } from "pages/loading/LoadingPage";

export const ProtectedRouteAuth = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => {
  const { isLoading, user } = useContext(AuthContext);

  if (isLoading) return <LoadingPage />;

  if (!user && !isLoading) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export const ProtectedRouteAdmin = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => {
  const { isLoading, user, isAdmin, isManager, isSuperAdmin } =
    useContext(AuthContext);

  if (isLoading) return <LoadingPage />;

  if (user && !isLoading && !isAdmin && !isManager && !isSuperAdmin) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};

export const ProtectedRouteNoSurvey = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => {
  const { isLoading, user, isAdmin, isSuperAdmin } = useContext(AuthContext);
  const isAdminOrSurveyDone = isAdmin || user?.isSurveyDone;

  if (isLoading) return <LoadingPage />;

  if (isSuperAdmin) {
    return <Navigate to="/companies" replace />;
  }

  if (user && !isLoading && isAdminOrSurveyDone) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};

export const ProtectedRouteNoAuth = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => {
  const { isLoading, user, isAdmin, isSuperAdmin } = useContext(AuthContext);
  const isAdminOrSurveyDone = isAdmin || isSuperAdmin || user?.isSurveyDone;

  if (isLoading) return <LoadingPage />;

  if (isSuperAdmin) {
    return <Navigate to="/companies" replace />;
  }

  if (user && !isLoading && isAdminOrSurveyDone) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};
