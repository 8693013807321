import { useMutation } from "@apollo/client";
import { REGISTER_MANAGER } from "../graphql/queries";

export const useRegisterManager = () => {
  const [registerManager, { data, error, loading }] =
    useMutation(REGISTER_MANAGER);

  const handleRegisterManager = (
    email: string,
    password: string,
    name: string,
    surname: string,
    birthdate: string,
    companyId: string,
    departmentName: string
  ) => {
    return registerManager({
      variables: {
        email,
        password,
        name,
        surname,
        birthdate,
        companyId,
        departmentName,
      },
    });
  };

  return {
    handleRegisterManager,
    data,
    error,
    loading,
  };
};
