import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import { Login } from "pages/login/Login";
import { Survey } from "pages/survey/Survey";
import { Dashboard } from "pages/dashboard/Dashboard";
import { SurveyManager } from "pages/survey-manager/SurveyManager";
import { Profile } from "pages/profile/Profile";

import italian from "translations/italian.json";
import { Users } from "pages/users/Users";
import { AuthContext } from "context/Auth";
import {
  ProtectedRouteAdmin,
  ProtectedRouteAuth,
  ProtectedRouteNoAuth,
  ProtectedRouteNoSurvey,
} from "./components/ProtectedRoute";
import { AdminDashboard } from "./pages/dashboard/AdminDashboard";
import { Register } from "./pages/register/Register";
import { ForgotPassword } from "./pages/forgotPassword/ForgotPassword";
import { paths } from "constants/paths";
import { Suspense, useContext } from "react";
import { AdminRegistration } from "pages/admin-registration/AdminRegistration";

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: {
          "Welcome to React": "Welcome to React and react-i18next",
        },
      },
      it: {
        translation: italian,
      },
    },
    lng: "it", // if you're using a language detector, do not define the lng option
    fallbackLng: "it",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export const App = () => {
  const { isAdmin, isManager, isSuperAdmin } = useContext(AuthContext);

  return (
    <Suspense fallback="Loading ...">
      <Toaster />

      <>
        <Routes>
          <Route
            path={paths.login}
            element={
              <ProtectedRouteNoAuth>
                <Login />
              </ProtectedRouteNoAuth>
            }
          />

          <Route
            path={paths.register}
            element={
              <ProtectedRouteNoAuth>
                <Register />
              </ProtectedRouteNoAuth>
            }
          />
          <Route
            path={paths.registerAdmin}
            element={
              <ProtectedRouteNoAuth>
                <AdminRegistration />
              </ProtectedRouteNoAuth>
            }
          />
          <Route
            path={paths.forgotPassword}
            element={
              <ProtectedRouteNoAuth>
                <ForgotPassword />
              </ProtectedRouteNoAuth>
            }
          />

          <Route
            path={paths.home}
            element={
              <ProtectedRouteAuth>
                {isSuperAdmin ? (
                  <Users />
                ) : isAdmin || isManager ? (
                  <AdminDashboard />
                ) : (
                  <Dashboard />
                )}
              </ProtectedRouteAuth>
            }
          />

          <Route
            path={paths.survey}
            element={
              <ProtectedRouteAuth>
                <ProtectedRouteNoSurvey>
                  <Survey />
                </ProtectedRouteNoSurvey>
              </ProtectedRouteAuth>
            }
          />

          <Route
            path={isSuperAdmin ? paths.companies : paths.dashboard}
            element={
              <ProtectedRouteAuth>
                {isSuperAdmin ? (
                  <Users />
                ) : isAdmin || isManager ? (
                  <AdminDashboard />
                ) : (
                  <Dashboard />
                )}
              </ProtectedRouteAuth>
            }
          />

          <Route
            path={paths.departmentUsers}
            element={
              <ProtectedRouteAuth>
                <Users />
              </ProtectedRouteAuth>
            }
          />

          <Route
            path={paths.results}
            element={
              <ProtectedRouteAuth>
                <Dashboard />
              </ProtectedRouteAuth>
            }
          />

          <Route
            path={paths.profile}
            element={
              <ProtectedRouteAuth>
                <Profile />
              </ProtectedRouteAuth>
            }
          />

          <Route
            path={paths.surveyManager}
            element={
              <ProtectedRouteAuth>
                <ProtectedRouteAdmin>
                  <SurveyManager />
                </ProtectedRouteAdmin>
              </ProtectedRouteAuth>
            }
          />
          <Route
            path={paths.users}
            element={
              <ProtectedRouteAuth>
                <ProtectedRouteAdmin>
                  <Users />
                </ProtectedRouteAdmin>
              </ProtectedRouteAuth>
            }
          />
          <Route
            path={paths.userResults}
            element={
              <ProtectedRouteAuth>
                <ProtectedRouteAdmin>
                  <Dashboard />
                </ProtectedRouteAdmin>
              </ProtectedRouteAuth>
            }
          />
          <Route
            path="*"
            element={
              <ProtectedRouteNoAuth>
                <Login />
              </ProtectedRouteNoAuth>
            }
          />
        </Routes>
      </>
    </Suspense>
  );
};
