import { LoginForm } from "components/forms/LoginForm";
import { LoginLayout } from "layout/LoginLayout";
import { ReactElement } from "react";

export const Login = (): ReactElement => {
  return (
    <LoginLayout heading="Welcome back">
      <LoginForm />
    </LoginLayout>
  );
};
