import {
  Box,
  HStack,
  Heading,
  IconButton,
  Tooltip,
  VStack,
  Flex,
} from "@chakra-ui/react";
import { BsThreeDots } from "react-icons/bs";

import { Circular } from "pages/dashboard/components/main/graphs/Circular";
import { useTranslation } from "react-i18next";
import { ReactElement } from "react";

type Props = {
  title: string;
  fontSize?: string;
  iconColor?: string;
  value?: number;
  changeValue?: number;
  valueText?: string;
  color: string;
  tooltipLabel: string;
};

export const ChangeCard = ({
  title,
  fontSize = "md",
  iconColor = "gray.500",
  value = 20,
  changeValue = 15,
  valueText = "Users",
  color = "#db00008",
  tooltipLabel = "",
}: Props): ReactElement => {
  const { t } = useTranslation();

  return (
    <Flex
      backgroundColor="white"
      borderRadius="md"
      position="relative"
      direction="column"
      w={296}
      h={182}
      padding="20px"
    >
      <Flex alignItems="center" justifyContent="space-between" w="100%">
        <Heading
          fontFamily="Mulish"
          fontWeight={700}
          fontSize="m"
          color="#262626"
          ml={2}
        >
          {t(title)}
        </Heading>
        <IconButton
          variant="ghost"
          color={iconColor}
          aria-label="See menu"
          icon={<BsThreeDots />}
          right={0}
          top={0}
        />
      </Flex>

      <Tooltip
        label={t(tooltipLabel)}
        hasArrow
        placement="auto-start"
        background="#DAD7FE"
        color="black"
        shadow=""
        fontFamily="Mulish"
      >
        <HStack justifyContent="center" alignItems="center" gap="55px">
          <VStack align="flex-start" spacing={2} alignItems="center">
            <Heading fontFamily="Nunito" fontWeight={700} fontSize="3xl">
              {value}
            </Heading>

            <Heading
              fontFamily="Mulish"
              fontWeight={700}
              fontSize="14px"
              alignSelf="center"
              color="#262626"
            >
              {t(valueText)}
            </Heading>
          </VStack>
          <Circular color={color} name="test" value={changeValue} />
        </HStack>
      </Tooltip>
    </Flex>
  );
};
