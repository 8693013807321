import { Box, Flex, Text } from "@chakra-ui/react";
import { ReactElement } from "react";

type Props = {
  currentStep: number;
  totalSteps: number;
};

const ProgressBar = ({ currentStep, totalSteps }: Props): ReactElement => {
  const progressPercentage = (currentStep / totalSteps) * 100;

  return (
    <Box background="#FFF0CC" h={4} width="100%" borderRadius="full">
      <Box
        bg="#FFB200"
        h="inherit"
        width={`${progressPercentage}%`}
        position="relative"
        borderRadius="inherit"
      >
        <Flex
          position="absolute"
          right={-10}
          bottom={-4.8}
          h={4}
          p={3}
          bg="#FFB200"
          border="2px solid white"
          borderRadius="inherit"
          align="center"
          transition="right 0.3s ease"
        >
          <Text whiteSpace="nowrap" fontSize="xs" color="white">
            {currentStep} / {totalSteps}
          </Text>
        </Flex>
      </Box>
    </Box>
  );
};

export default ProgressBar;
